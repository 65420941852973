import React, { useEffect, useState } from 'react'
import InputComponent from './InputComponent'
import Location from './Location'
import { addressAddFetch, addressListFetch, addressUpdate } from '../redux/features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


const EditAddress = ({editAddress, setEditAddress}) => {

    const state = useSelector((state) => state?.userInfo)
    const dispatch = useDispatch()
    const coordinate = useSelector((state) => state.userInfo.coordinates)
    const [currentPosition, setCurrentPosition] = useState({
        lat: 22.7411203,
        lng: 75.9185608
    });
    const [fullAddress, setFullAddress] = useState("")
    const [updateAddress, setUpdateAddress] = useState({...editAddress})
    

    useEffect(() => {
    setUpdateAddress({...editAddress})
    }, [editAddress])
    


    useEffect(() => {
        dispatch(addressListFetch())
    }, [])

    const handleRemove = (id) => {
        const updatedAddress = {
          ...updateAddress,
          _method: "put",
        };
        setUpdateAddress("");
        setEditAddress("");
      
        console.log(JSON.stringify(updatedAddress));
      
        dispatch(addressUpdate(JSON.stringify({ ...updateAddress, "address": fullAddress, 'latitude': coordinate.lat, 'longitude': coordinate.lng }), id));
      };


    useEffect(() => {
        state?.address?.message && dispatch(addressListFetch())
    }, [state?.address?.message])


 

    const success = position => {
        const currentPosition = {
            lat:position.coords.latitude,
            lng:position.coords.longitude
        }
        setCurrentPosition(currentPosition);
    };

    const mapStyles = {
        height: "300px",
        width: "100%"
    }; 
    
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    }, [])



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUpdateAddress((prevFormData) => ({ ...prevFormData, [name]: value }));
    };


    return (
        <div>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                <h5 className="border border-bottom-0 heading fs2rem m-0 p-3">Edit Address</h5>
                <div className='border pt-2 px-2'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                        <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={13}
                                center={currentPosition}
                            >
                                {

                                    currentPosition.lat && (<Marker position={currentPosition} />
                                    )
                                }
                            </GoogleMap>
                            <InputComponent
                                onChange={handleChange}
                                value={updateAddress?.contact_person_name}
                                type='text'
                                label='Full Name'
                                id='contact_person_name'
                                name='contact_person_name' />
                        </div>

                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3'>


                            <div onChange={handleChange} >
                                <label className='mb-2' >Address Type</label>
                                <br />
                                <input type="radio" id='Home' value="Home" name="address_type" checked={updateAddress?.address_type === "Home"} />
                                <label htmlFor='Home'>Home</label>
                                <input type="radio" className='ms-3 ' id='Workplace' value="Workplace" name="address_type" checked={updateAddress?.address_type === "Workplace"} />
                                <label htmlFor='Workplace'>Workplace</label>

                                <input type="radio" className='ms-3' id='Other' value="Other" name="address_type" checked={updateAddress?.address_type === "Other"} />
                                <label htmlFor='Other'>Other</label>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3'>
                            <Location setFullAddress={setFullAddress} setCurrentPosition={setCurrentPosition} />
                        </div>

                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                            <InputComponent
                                onChange={handleChange}
                                value={updateAddress?.contact_person_number}
                                type='text'
                                label='Phone'
                                id='contact_person_number'
                                name='contact_person_number' />
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => {handleRemove(updateAddress.id)}} className="btn btn-dark rounded-0" data-bs-dismiss="modal">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAddress
