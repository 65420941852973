import React from 'react'
import { useSelector } from 'react-redux';

const TermsAndCondition = () => {

  const config = JSON.parse(localStorage.getItem("config"))
  return (
    <div className='my-3' >
      <h3 className='heading mb-3' >Terms and Conditions</h3>
      <div dangerouslySetInnerHTML={{ __html: config.terms_and_conditions}} />
    </div>
  )
}

export default TermsAndCondition
