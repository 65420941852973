import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../axios'


const initialState={
    allProducts:[],
    productType:[],
    status:"loading",
    error:"",
}



export const allProductsFetch = createAsyncThunk(
  "allProductsFetch" ,
  async(offset)=>{
    console.log(offset, 'offset')
    const response= await axios.get(`/products/latest?limit=12&&offset=${offset}`)
    return response?.data
      }
)

export const productTypeFetch = createAsyncThunk(
  "productTypeFetch" ,
  async(id)=>{
    const response= await axios.get(`/categories/childes/${id}`)
    return response?.data
      }
)


const allProductsSlice = createSlice({
    name:"allProducts",
    initialState,
    reducers:{
        emptyAllProducts(state,action){
            state.allProducts = []
            localStorage.removeItem("allProducts")
        }
    },
    extraReducers: (builder) => {
        builder.addCase(allProductsFetch.pending,(state, action) => {
            state.status = "pending";
          })
          .addCase(allProductsFetch.fulfilled,(state, action) => { 
           state.allProducts = action.payload
           state.status = "fulfilled"

          })
          .addCase(allProductsFetch.rejected,(state, action ,errors) => {
            state.status = "rejected"; 
            state.error = errors    
          })
          .addCase(productTypeFetch.fulfilled,(state, action) => { 
            state.productType = action.payload
           })
                   
      },
})



export const {emptyAllProducts} = allProductsSlice.actions;
export default allProductsSlice.reducer