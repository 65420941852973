import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";


const initialState={
    products:[],
    status:"loading",
    reviewMsg:[],
    delieverymanReviewMsg:[],
    reviews:[]
}



const token = localStorage.getItem("logintoken")
const config = {
  headers: {
    "Authorization": `Bearer ${token}`,
    "content-type": "application/json; charset=UTF-8",
  }
}

export const productsFetch = createAsyncThunk(
  "productsFetch" ,
  async(id)=>{
    const response= await axios.get(`/categories/products/${id}`)
    localStorage.setItem("products", JSON.stringify(response?.data))
    return response?.data
      }
)


export const submitProductReview = createAsyncThunk(
  "submitProductReview",
  async (data) => {
    try {
      const response = await axios.post(`/products/reviews/submit`,data, config)
      console.log(response, "review submitted")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)


export const getProductReview = createAsyncThunk(
  "getProductReview",
  async (id) => {
    try {
      const response = await axios.get(`/products/reviews/${id}`)
      console.log(response, "review get")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)



export const delieverymanReview = createAsyncThunk(
  "delieverymanReview",
  async (data) => {
    try {
      const response = await axios.post(`/delivery-man/reviews/submit`,data, config)
      console.log(response, "deliervryyman")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)







const productsSlice = createSlice({
    name:"products",
    initialState,
    reducers:{
      emptyProducts(state,action){
          state.products = []
          localStorage.removeItem("products")
      }
    },
    extraReducers: (builder) => {
        builder.addCase(productsFetch.pending,(state, action) => {
            state.status = "pending";
          })
          .addCase(productsFetch.fulfilled,(state, action) => { 
           state.products = action.payload
           state.status = "fulfilled"

          })
          .addCase(productsFetch.rejected,(state, action) => {
            state.status = "rejected";        
          }).addCase(submitProductReview.fulfilled,(state, action) => { 
            console.log(action.payload)
            state.reviewMsg = action.payload
           }).addCase(delieverymanReview.fulfilled,(state, action) => { 
            state.delieverymanReviewMsg = action.payload
           }).addCase(getProductReview.fulfilled,(state, action) => { 
            state.reviews = action.payload
           })         
      },
})



export const {emptyProducts} = productsSlice.actions
export default productsSlice.reducer