import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import { productsFetch } from '../redux/features/productSlice';
import { Link } from 'react-router-dom';
import { emptyAllProducts, productTypeFetch } from '../redux/features/allProductSlice';






const HomeCategory = () => {

    const dispatch = useDispatch()
    const config = JSON.parse(localStorage.getItem("config"))
    const categories = JSON.parse(localStorage.getItem("categories"))
    const baseURL = config?.base_urls?.category_image_url




    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }



    return (
        <div className='container my-5 mb-5'>
            <h3 className='text-center my-2 heading'>Popular Categories</h3>
            <p className='text-center mb-5' >Some of our popular categories</p>
            <Carousel responsive={responsive}>
                {categories?.map((category) => {
                    return (
                        <div key={category.id} className='px-1'>
                            <div className='position-relative'>
                                <div className='text-center'>
                                    <Link to='/shop' >
                                        <img onClick={() => { dispatch(productsFetch(category.id)); scrollToTop(); dispatch(productTypeFetch(category.id)); dispatch(emptyAllProducts()) }} src={`${baseURL}/${category.image}`} className='slider_img' alt={category.name} />
                                        <h6 onClick={() => { dispatch(productsFetch(category.id)); scrollToTop(); dispatch(productTypeFetch(category.id)); dispatch(emptyAllProducts()) }} className='mt-3'>{category.name}</h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default HomeCategory;
