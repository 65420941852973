import React from 'react'
import { Rating } from 'react-simple-star-rating'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import {IoIosArrowDown} from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { addCart, getTotalAmount } from '../redux/features/cartSlice'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addWishList, getWishList } from '../redux/features/wishlistSlice'
import ProductReview from './ProductReview'




const SingleProduct = () => {

  const dispatch = useDispatch()
  const singleProduct = JSON.parse(localStorage.getItem("singleProduct"))
  const status = useSelector((state) => state.singleProduct?.status)
  const config = JSON.parse(localStorage.getItem("config"))
  const wishlistItems = useSelector((state) => state.wishlist?.wishlistItems)
  const baseURL = config?.base_urls?.product_image_url
  const loginToken = localStorage.getItem("logintoken")
  const state = useSelector((state) => state?.cart)


  const scrollToTop = () => {
    window.scrollTo(0, 0)
}


  const notify = () => {
    if (loginToken) {
      toast(`Successfully added to cart!`, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast("You are not logged in", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };


  const notify1 = () => {
    if (loginToken) {
      toast("Added to Wishlist", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast("You are not logged in", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

console.log(singleProduct, 'selected')


  return (

    status == 'pending' ?
      <div className='loader'>
        <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
      </div> :
      <div className='container'>
        <div className='single-product-outer mb-5'>
          <div className='row' >
            <div className='col-md-6' >
              <div className='single-product-img text-center' >
                <img src={`${baseURL}/${singleProduct?.image[0]}`} className='img-fluid' />
              </div>
            </div>
            <div className='col-md-6' >
              <div className='single-product-detail'>
                <h2>{singleProduct.name}</h2>
                <p className='my-3 pointer' onClick={()=>{dispatch(addWishList(singleProduct.id)); notify1();dispatch(getWishList())}} >Add to favourite <BsHeart onClick={()=>{dispatch(addWishList(singleProduct.id))}} className='heart-empty' /></p>
                <Rating initialValue={singleProduct?.rating[0]?.average} size={20} readonly={true} />
                <h3 className='shop-price my-3' >{config?.currency_symbol}{singleProduct.price}</h3>
                <div className='d-flex flex-row' >
                  <div className='quantity' >
                    <h5>1</h5>
                  </div>
                  {singleProduct?.total_stock <= 0 ? <p className='slide-btn error' >Out of Stock</p> :<p onClick={() => { dispatch(addCart(JSON.stringify({product_id:singleProduct.id,quantity:1 }))); notify() }} className='slide-btn'>Add to Cart</p>}
                </div>
                <div className='my-3' >
                  <h2>Description</h2>
                  <div dangerouslySetInnerHTML={{ __html: singleProduct.description }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <ProductReview />
      </div>

  )
}
export default SingleProduct
