import React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom';
import { BsHandbagFill } from 'react-icons/bs'
import { ImUser } from 'react-icons/im'
import { PiNotePencilFill } from 'react-icons/pi'
import {BiSolidMap} from 'react-icons/bi'

const User = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-3 col-lg-3 col-xlg-3 bdr_right' >
                    <div className='user-nav'>
                        <h3 className='heading'>Account</h3>
                        <ul className='user-detail' >
                            <NavLink to='/user/profile'
                                className={({ isActive }) =>
                                    (isActive ? "navColor" : "nav-link")}
                            >

                                <li> <ImUser className='user-detail-icon' /> Profile</li>

                            </NavLink>
                            <NavLink to='/user/order'
                                className={({ isActive }) =>
                                    (isActive ? "navColor" : "nav-link")}
                            >

                                <li><BsHandbagFill className='user-detail-icon' /> My order</li>

                            </NavLink>

                            <NavLink to='/user/termsandCondition'
                                className={({ isActive }) =>
                                    (isActive ? "navColor" : "nav-link")}
                            >

                                <li> <PiNotePencilFill className='user-detail-icon' />Terms & Conditions</li>

                            </NavLink>

                            <NavLink to='/user/user-address'
                                className={({ isActive }) =>
                                    (isActive ? "navColor" : "nav-link")}
                            >

                                <li> <BiSolidMap className='user-detail-icon' />Address</li>

                            </NavLink>


                        </ul>
                    </div>
                </div>
                <div className='col-md-8 col-lg-8 col-xl-8 user-div' >
                    <div className='container'>
                        <div className='row'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default User;
