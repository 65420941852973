import React from 'react'
import payment from '../assets/img/payment.png'
import { Link } from 'react-router-dom'



const Footer = () => {

    const config = JSON.parse(localStorage.getItem("config"))
    const loginToken = localStorage.getItem("logintoken")

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <div className='footer'>
            <div className='container-fluid'>
                <footer>
                    <div className='row footerSpace'>

                        <div className='col-12 col-md-4' >
                            <div >
                                <div>
                                    <h3 className='heading'>{config?.ecommerce_name}</h3>
                                    <p>Subscribe to our Newsletter for latest updates</p>
                                    <div class="input-group mb-3 col-md-4">
                                        <input type="text" className="form-control searchBar" placeholder='Enter Your Email' aria-label="Recipient's username" aria-describedby="button-addon2" />
                                        <button className="btn  btn-outline-dark" type="button" id="button-addon2">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4"></div>
                        <div className="col-12 col-md-4 my-auto">
                            <div className='row' >
                                <div className='col-6 col-md-6' onClick={scrollToTop} >
                                    <Link to='/' ><li>Delivery</li></Link>
                                    {/* <Link to='/user/order' ><li>My Order</li></Link> */}
                                    <Link to='/aboutus' ><li>About us</li></Link>
                                </div>
                                <div className='col-6 col-md-6' onClick={scrollToTop}>
                                    <Link to='/contactus' ><li>Contact us</li></Link>
                                    <Link to='/termsandCondition'><li>Term & Conditions</li></Link>
                                    <Link to='/contactus'><li>Address</li></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row copyright">
                        <div className='col-md-9'>
                            <p>Copyright © 2023 All Rights Reserved</p>
                        </div>
                        <div className='col-md-3'>
                            <img src={payment} className='img-fluid' alt='payment mode' />
                            <div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            {/* <ChatButton /> */}
        </div>
    )
}

export default Footer
