import React, { useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Rating } from 'react-simple-star-rating'
import { BsHeart } from 'react-icons/bs'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { singleProductFetch } from '../redux/features/singleProductSlice';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  addWishList, getWishList } from '../redux/features/wishlistSlice';
import { getProductReview } from '../redux/features/productSlice';



const HomeProduct = () => {

  const dispatch = useDispatch()
  const config = JSON.parse(localStorage.getItem("config"))
  const wishlistItems = useSelector((state) => state.wishlist.wishlistItems)
  const status = useSelector((state) => state.allProducts.status)
  const baseURL = config?.base_urls?.product_image_url
  const allProducts = useSelector((state) => state.allProducts.allProducts.products)
  const loginToken = localStorage.getItem("logintoken")



  const [clicked, setClicked] = useState(false)

  const notify1 = () => {
    if (loginToken) {
      toast("Added to Wishlist", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast("You are not logged in", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const handleClicked = () => {
    setClicked(!clicked)
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  return (

    status == 'pending' ?
      <div className='loader'>
        <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
      </div>
      :
      <div className='container my-5'>
        <h3 className='text-center my-2 heading'>New Arrivals</h3>
        <p className='text-center mb-5' >Add our products to weekly line up</p>
        <Carousel responsive={responsive}>
          {allProducts?.map((product) => {
            return (
              <div key={product.id} className='px-1'>
                <div className='position-relative carousal-product'>
                  {/* <p className='my-3 pointer'  onClick={() => { dispatch(addToWishList(product))}} >{(wishlistItems?.some((item)=>item.id == product.id))? <BsHeartFill className='icon heart-fill' /> : <BsHeart className='icon heart-empty' /> }</p> */}
                  <BsHeart onClick={() => { dispatch(addWishList(product.id)); notify1() ;dispatch(getWishList()) }} className='icon heart-empty' />
                  <div className='text-center'>
                    <Link to='/single-product' onClick={() => {dispatch(singleProductFetch(product.id)); dispatch(getProductReview(product.id));scrollToTop()}} >
                      <img src={`${baseURL}/${product.image[0]}`} className='fixed_img' alt={product.name} />
                      <p className='mb-0 mt-3 productsTitle' >{product.name}</p>
                      <Rating initialValue={product?.rating[0]?.average} size={15} readonly={true} />
                      <h5>{config?.currency_symbol}{product.price}</h5>
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </Carousel>
          <ToastContainer />
      </div>
  )
}

export default HomeProduct;
