import React, { useEffect } from 'react'
import SingleProduct from '../Components/SingleProduct'
import HomeProduct from '../Components/HomeProduct'
import { useDispatch, useSelector } from 'react-redux'
import { allProductsFetch } from '../redux/features/allProductSlice'

const SingleProductPage = () => {
  const dispatch = useDispatch()
  const logintoken = useSelector((state) => state?.auth?.loginToken)
  const token = localStorage.getItem("logintoken")


  const min = 1;
  const max = 10;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;


  useEffect(() => {
      dispatch(allProductsFetch(randomNumber))
  }, [token, logintoken])



  return (
    <div>
      <SingleProduct />
      <HomeProduct />
    </div>
  )
}

export default SingleProductPage
