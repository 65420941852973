import React, { useState, useEffect } from 'react'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { singleProductFetch } from '../redux/features/singleProductSlice'
import { getProductReview, productsFetch } from '../redux/features/productSlice'
import { ImSad2 } from 'react-icons/im'
import { allProductsFetch, emptyAllProducts } from '../redux/features/allProductSlice'
import Pagination from './Pagination'
import { addCart, fetchCart } from '../redux/features/cartSlice'
import ReactLoading from 'react-loading';
import { addWishList, getWishList } from '../redux/features/wishlistSlice'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ShopMain = () => {
    const dispatch = useDispatch()
    const products = useSelector((state) => state.products.products);
    const status = useSelector((state) => state.products.status)
    const productType = useSelector((state) => state.allProducts.productType)
    const allProducts = useSelector((state) => state.allProducts.allProducts.products)
    const config = JSON.parse(localStorage.getItem("config"))
    const wishlistItems = useSelector((state) => state?.wishlist?.wishList)

    // const wishlistItems = useSelector((state) => state.wishlist.wishlistItems)
    const wishUpdate = useSelector((state) => state.wishlist)
    const cartUpdate = useSelector((state) => state.cart)
    const loginToken = localStorage.getItem("logintoken")
    const state = useSelector((state) => state?.cart)
    const baseURL = config?.base_urls?.product_image_url



    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }







    useEffect(() => {
        wishUpdate?.wishlistmsg?.message && dispatch(getWishList())
    }, [wishUpdate?.wishlistmsg?.message])

    useEffect(() => {
        cartUpdate?.state?.message && dispatch(fetchCart())
    }, [cartUpdate?.state?.message])




    const notify = () => {
        if (loginToken) {
            toast('Successfully added to cart!', {
                autoClose: 3000,
                hideProgressBar: true,
            });
        } else {
            toast("You are not logged in", {
                autoClose: 3000,
                hideProgressBar: true,
            });
        }
    };



    const notify1 = () => {
        if (loginToken) {
            toast("Added to Wishlist", {
                autoClose: 3000,
                hideProgressBar: true,
            });
        } else {
            toast("You are not logged in", {
                autoClose: 3000,
                hideProgressBar: true,
            });
        }
    };


    return (
        status == 'pending' ?
            <div className='loader'>
                <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
            </div>
            :
            <div className='shop-outer py-5' >
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-2 col-lg-2 col-xl-2 mb-3'>
                            {
                                allProducts?.length ?
                                    null
                                    :
                                    <div className='shop-sidebar'>
                                        {productType?.map((type) => {
                                            return (
                                                <h6 key={type.id} onClick={() => { dispatch(productsFetch(type.id)); dispatch(emptyAllProducts()) }}>{type.name}<MdKeyboardArrowRight /> </h6>
                                            )
                                        })}
                                    </div>
                            }

                        </div>
                        {
                            products?.length === 0 ?
                                (<>

                                    <div className='col-md-12 col-lg-12 col-xl-12' >
                                        <div className='grid_all' >
                                            {allProducts?.map((product) => {
                                                return (

                                                    <div key={product.id} onClick={() => { dispatch(singleProductFetch(product.id)); dispatch(getProductReview(product.id)); scrollToTop() }} className='position-relative'>
                                                        <Link to='/single-product' ><img src={`${baseURL}/${product.image[0]}`} alt={product.name} className='shopImages' /></Link>
                                                        <BsHeart onClick={() => { dispatch(addWishList(product.id)); notify1(); dispatch(getWishList()) }} className='heart' />
                                                        <Link to='/single-product' ><h6 className="text-center mt-4  px-4 productsTitle" >{product.name}</h6></Link>
                                                        <h5 className="shop-price text-center">{config?.currency_symbol}{product.price}</h5>
                                                        {(product?.total_stock <= 0) ? <p className='slide-btn error' >Out of stock</p> : <p className='slide-btn' onClick={() => { dispatch(addCart(JSON.stringify({ product_id: product.id, quantity: 1 }))); notify(); dispatch(fetchCart()) }} >Add To Cart</p>}
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </div>
                                    {
                                        allProducts?.length ? <Pagination /> :
                                            <div className='text-center'>
                                                <ImSad2 className='sad-icon' />
                                                <p>No Product Found</p>
                                            </div>
                                    }
                                </>
                                ) :
                                (products?.length === 0 ? <p>Products not Found</p> :
                                    <div className='col-md-10 col-lg-10 col-xl-10 grid_center' >
                                        <div className='grid' >
                                            {products?.map((product) => {
                                                return (

                                                    <div onClick={() => { dispatch(singleProductFetch(product.id)); dispatch(getProductReview(product.id)); scrollToTop() }} className='position-relative' key={product.id} >
                                                        <Link to='/single-product'><img src={`${baseURL}/${product.image[0]}`} className='shopImages' alt={product.name} /></Link>
                                                        <BsHeart onClick={() => { dispatch(addWishList(product.id)); notify1(); dispatch(getWishList()) }} className='heart' />
                                                        <Link to='/single-product'><h6 className="text-center mt-4  px-4 productsTitle" >{product.name}</h6></Link>
                                                        <h5 className="shop-price text-center">{config?.currency_symbol}{product.price}</h5>
                                                        {(product?.total_stock <= 0) ? <p className='slide-btn error' >Out of stock</p> : <p className='slide-btn' onClick={() => { dispatch(addCart(JSON.stringify({ product_id: product.id, quantity: 1 }))); notify(); dispatch(fetchCart()) }} >Add To Cart</p>}
                                                    </div>

                                                )
                                            })}
                                            {
                                                products?.length === 0 ? <p>Product not Found</p> : null
                                            }
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <ToastContainer />
            </div>


    )
}

export default ShopMain;
