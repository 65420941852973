import React from 'react'

const InputComponent = ({
    type,
    label,
    id,
    name,
    value,
    onChange,
    ref
    
}) => {
    return (
        <div className="form-floating mb-3">
            <input type={type} ref={ref} className="form-control" onChange={onChange} value={value} id={id} name={name} placeholder='' />
            <label for={id}>{label}</label>
        </div>
    )
}

export default InputComponent
