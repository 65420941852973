import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../axios';


const initialState={
  orderPlacedmsg:[],
  trackingDetails:[],
  orderList:[],
  payment:[],
  myOrder:[],
  status:'loading',
  getOrderMsg:[],
  id:""
}

const token = localStorage.getItem("logintoken")

const config = {
    headers: {
        "Authorization": `Bearer ${token}`,
        "content-type": "application/json; charset=UTF-8",

    }
}



export const placeOrder = createAsyncThunk(
  "placeOrder" ,
  async(order)=>{
   try {
      console.log(order, 'thiss')
      const response= await axios.post('/customer/order/place', order, config)
      console.log(response, 'HURRAY')
      localStorage.setItem("arzoo",JSON.stringify(response?.data))
      return response?.data
  } catch (error) {
      console.log(error.message)
  }
      }
)

export const orderList = createAsyncThunk(
    "orderList" ,
    async () => {
        try {
            const response = await axios.get(`/customer/order/list`, config)
            localStorage.setItem("orderList", JSON.stringify(response?.data))
            console.log(response, 'user')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
  )


  export const MyOrder = createAsyncThunk(
    "MyOrder" ,
    async (id) => {
        try {
            const response = await axios.get(`/customer/order/details?order_id=${id}`, config)
            localStorage.setItem("myOrder", JSON.stringify(response?.data))
            console.log(response, 'detaill')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
  )


  export const updatePayment = createAsyncThunk(
    "updatePayment" ,
    async (data) => {
        console.log(data, 'update payment data')
        try {
            const response = await axios.put(`/customer/order/payment-method`, data, config)
            console.log(response, 'paymnet')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
  )


  export const cancelOrder = createAsyncThunk(
    "cancelOrder" ,
    async (id) => {
        try {
            const response = await axios.put(`/customer/order/cancel`,{
              "order_id":`${id}`,
              "_method":"put"
            }, config)
            console.log(response, 'cancel cancel')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
  )


  
  export const trackOrder = createAsyncThunk(
    "trackOrder" ,
    async (id) => {
        try {
            const response = await axios.get(`/customer/order/track?order_id=${id}`, config)
            // console.log(response, 'trackkkkkkkk')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
  )


  export const orderMessage = createAsyncThunk(
    "orderMessage" ,
    async (id) => {
        try {
            const response = await axios.get(`/customer/message/get-order-message?offset=1&limit=100&order_id=${id}`, config)
            console.log(response?.data)
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
  )

  


const orderSlice = createSlice({
    name:"order",
    initialState,
    reducers:{
       removeid(state,action){
            state.id = ""
        },
        removeState(sate,action){

        }
    },
    extraReducers: (builder) => {
        builder.addCase(placeOrder.pending,(state, action) => {
          })
          .addCase(placeOrder.fulfilled,(state, action) => { 
           state.orderPlacedmsg = action.payload;
           state.id = action.payload?.order_id
           state.payment=[]
           console.log(state.id, 'state updatedddd')
          })
          .addCase(orderList.fulfilled,(state, action) => { 
            state.orderList = action.payload;
            state.orderPlacedmsg={}
            state.payment=[]
            state.status = 'fulfilled'
           })
           .addCase(cancelOrder.fulfilled,(state, action) => { 
            state.orderPlacedmsg = action.payload;
            state.payment=[]
            console.log(state.cancelOrdermsg, 'msggg')
           }) .addCase(MyOrder.fulfilled,(state, action) => { 
            state.status = "fulfilled"
            state.payment=[]
            state.orderPlacedmsg = action.payload;
            state.myOrder = action.payload;
           }).addCase(updatePayment.fulfilled,(state, action) => { 
            state.orderPlacedmsg = [];
            state.payment = action.payload
           }).addCase(trackOrder.fulfilled,(state, action) => { 
            state.trackingDetails = action.payload;
            
           }).addCase(orderMessage.fulfilled,(state, action) => { 
            console.log(action.payload, 'jhjhhkjh')
            state.getOrderMsg = action.payload;          
           })
                             
      },
})


export const {removeid} = orderSlice.actions
export default orderSlice.reducer