import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Home from './layout/Home';
import Shop from './layout/Shop';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MainHead from './Components/MainHead';
import Footer from './Components/Footer';
import SignupPage from './layout/SignupPage';
import LoginPage from './layout/LoginPage';
import ShoppingCart from './layout/ShoppingCart';
import { Provider } from 'react-redux';
import TermsAndCondition from './Components/TermsAndCondition';
import User from './layout/User';
import Profile from './Components/Profile';
import Order from './Components/Order';
import AboutUs from './layout/AboutUs';
import ContactUs from './layout/ContactUs';
import WishlistPage from './layout/WishlistPage';
import SingleProductPage from './layout/SingleProductPage';
import Review from './Components/Review';
import {store } from './redux/store';
import Checkout from './layout/Checkout';
import UserAddress from './Components/UserAddress';
import Notification from './Components/Notification';
import ForgetPassword from './layout/ForgetPassword';
import OrderPlaced from './Components/OrderPlaced';
import TrackOrder from './Components/TrackOrder';
import ViewOrder from './Components/ViewOrder';



function App() {


  return (
    <div >
      <Provider store={store} >
        <Router>
          <MainHead />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path="/shop" element={<Shop />} />
            <Route exact path='/single-product' element={<SingleProductPage />}/>        
            <Route exact path='/login' element={<LoginPage />} />
            <Route exact path='/signup' element={<SignupPage />} />
            <Route exact path='/user' element={<User />}>
              <Route index element={<Profile />} />
              <Route exact path='/user/profile' active element={<Profile />} />
              <Route exact path='/user/termsandCondition' element={<TermsAndCondition />} />
              <Route exact path='/user/order' element={<Order />} />
              <Route exact path='/user/user-address' element={<UserAddress />} />
              <Route exact path='/user/view-order' element={<ViewOrder />} />
              <Route exact path='/user/review/:orderId/:productId/:reviewType' element={<Review />} />

            </Route>
            <Route exact path='/termsandCondition' element={<TermsAndCondition />} />
            <Route exact path='/contactus' element={<ContactUs />} />
            <Route exact path='/aboutus' element={<AboutUs />} />
            <Route exact path='/cart' element={<ShoppingCart />} />
            <Route exact path='/checkout' element={<Checkout />} />
            <Route exact path='/wishlist' element={<WishlistPage />} />
            <Route exact path='/notification' element={<Notification />} />
            <Route exact path='/forget-password' element={<ForgetPassword />} />
            <Route exact path='/order-placed' element={<OrderPlaced />} />
            <Route exact path='/track-order' element={<TrackOrder />} />
          </Routes>
          <Footer />
        </Router>
      </Provider>
    </div>
  );
}

export default App;
