import React from 'react'
import { useSelector } from 'react-redux';
import InputComponent from '../Components/InputComponent';

const ContactUs = () => {

  const config = JSON.parse(localStorage.getItem("config"))

  return (
    <>
      <div className='container'>
        <div className='row my-5'>
          <div className='col-xl-6 col-lg-6 col-md-6'>
            <h4 className='mt-4'>Don’t worry!</h4>
            <h2>If you have any query? <br />Contact with us.</h2>
            <div className='row mt-4'>
              <div className='col-xl-6 col-lg-6 col-md-6'>
                <InputComponent label='Name' type='text' id='name-input' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6'>
                <InputComponent label='Email' type='email' id='email-input' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6'>
                <InputComponent label='Phone' type='tel' id='phone-input' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6'>
                <InputComponent label='Address' type='text' id='address-input' />
              </div>
              <div className='col-xl-12 col-lg-12 col-md-12'>
                <div className="form-floating mb-3">
                  <textarea className="form-control h10" id="messageInput" placeholder=''></textarea>
                  <label for="messageInput">Message</label>
                </div>
                <button onClick={() => { alert("Thank You For Your Message") }} className='btn btn-dark rounded-0 w-50 p-2'>Submit</button>
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6'>
            <div className='mt-5 mx-4'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.163608791875!2d-118.46308132535569!3d33.98833112110416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2ba95d42541f5%3A0x79808c6f383feda0!2s1800%20Abbot%20Kinney%20Blvd%2C%20Venice%2C%20CA%2090291%2C%20USA!5e0!3m2!1sen!2sin!4v1694438497930!5m2!1sen!2sin" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6'>
            <div className='contact-info'>
              <h3 className='contactheading' >Head Office</h3>
              <h6 className='title'>Phone:</h6>
              <p>
                <a href={`tel:${config.ecommerce_phone}`}>{config.ecommerce_phone}</a>
              </p>
              <h6 className='title mt-4'>Email:</h6>
              <p>
                <a href={`mailto:${config.eecommerce_email}`}>{config.ecommerce_email}</a>
              </p>
              <h6 className='title mt-4'>Address:</h6>
              <p>
                {config.ecommerce_address}
              </p>
            </div>
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6'>
            <div className='contact-info'>
              <h3 className='contactheading' >Branch</h3>
              <h6 className='title'>Name</h6>
              <p>
                {config?.branches[0].name}
              </p>
              <h6 className='title mt-4'>Email:</h6>
              <p>
                <a href={`mailto:${config?.branches[0].email}`}>{config?.branches[0].email}</a>
              </p>
              <h6 className='title mt-4'>Address:</h6>
              <p>
                <a>{config.branches[0].address}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs;