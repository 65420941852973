import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";




const initialState = {
  state:[],
  cartItems: [],
  cartTotalAmount: 0
}

const itemCart = JSON.parse(localStorage.getItem("cart"))

const token = localStorage.getItem("logintoken")
const config = {
  headers: {
    "Authorization": `Bearer ${token}`,
    "content-type": "application/json; charset=UTF-8",
  }
}


export const addCart = createAsyncThunk(
  "addCart",
  async (cart) => {
    try {
      const response = await axios.post(`/customer/cart/addToCart`,cart, config)
      console.log(response, "added to cartt")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)


export const fetchCart = createAsyncThunk(
  "fetchCart",
  async () => {
    try {
      const response = await axios.get(`/customer/cart/fetchCartItems`, config)
      localStorage.setItem("cart", JSON.stringify(response?.data))
      console.log(response, "fetch from cartt")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)


export const removeCart = createAsyncThunk(
  "removeCart",
  async (id) => {
    try {
      const response = await axios.post(`/customer/cart/removeFromCart`,id, config)
      console.log(response, "removed from cartt")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)


const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(removeCart.fulfilled, (state, action) => {
      state.state = action.payload
  }).addCase(addCart.fulfilled, (state, action) => {
      state.state = action.payload
  }).addCase(fetchCart.fulfilled, (state, action) => {
    state.cartItems = action.payload
    state.state = []
  })
  }

})




export const {  } = cartSlice.actions;
export default cartSlice.reducer