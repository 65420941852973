import React, { useEffect, useRef } from 'react'
import { BsHandbag, BsSearch } from 'react-icons/bs'
import { FiHeart } from 'react-icons/fi'
import { Link, NavLink, json, useNavigate } from 'react-router-dom'
import { PiVan } from 'react-icons/pi'
import { BsBoxSeam, BsPersonFill } from 'react-icons/bs'
import { FaRegBell } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { emptyProducts, productsFetch } from '../redux/features/productSlice'
import { allProductsFetch, emptyAllProducts, productTypeFetch } from '../redux/features/allProductSlice'
import { useState } from 'react'
import { logout } from '../redux/features/authSlice'
import { removeInfo } from '../redux/features/userSlice'
import { getWishList } from '../redux/features/wishlistSlice'
import { bannerFetch, categoriesFetch, configFetch, notificationFetch, searchFetch } from '../redux/features/configSlice'
import { singleProductFetch } from '../redux/features/singleProductSlice'
import { fetchCart } from '../redux/features/cartSlice'
import Chatbox from './Chatbox'



const MainHead = () => {

    const [search, setSearch] = useState('')
    const [expanded, setExpanded] = useState(false);
    const [login, setLogin] = useState(localStorage.getItem("logintoken"))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cartItems = JSON.parse(localStorage.getItem("cart"))
    const wishlistItems = useSelector((state) => state.wishlist.wishList)
    const allProducts = useSelector((state) => state.allProducts.allProducts.products)
    const config = JSON.parse(localStorage.getItem("config"))
    const searchProducts = useSelector((state) => state.config?.searchItem?.products);
    const baseURL = config?.base_urls?.ecommerce_image_url
    const categories = JSON.parse(localStorage.getItem("categories"))
    const loginToken = localStorage.getItem("logintoken")
    const loginKey = useSelector((state) => state.auth.loginToken)
    const baseURL_search = config?.base_urls?.product_image_url
    const cartUpdate = useSelector((state) => state.cart)
    const wishUpdate = useSelector((state) => state.wishlist)


    const inputref = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);


    function handleDocumentClick(event) {
        if (!inputref.current.contains(event.target) && !event.target.classList.contains('searchRes')) {
            close();
        }
    }





    useEffect(() => {
        dispatch(allProductsFetch(1))
        dispatch(categoriesFetch())
        dispatch(configFetch())
        dispatch(bannerFetch())
        dispatch(getWishList())
        dispatch(notificationFetch())
    }, [])


    useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute(
            'src',
            '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: 'en',
                includedLanguages: 'en,de,nl', // include this for selected languages
                layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                disableAutoTranslation: true, // Disable automatic translation
                disableAutoLanguageDetection: true, // Disable automatic language detection
                disableSuggestions: true, // Disable suggestions
            },
            'google_translate_element'
        );
    };




    useEffect(() => {
        dispatch(getWishList())
        dispatch(fetchCart())
    }, [wishUpdate?.wishlistmsg?.message, cartUpdate?.state?.message])

    const handleSearch = (e) => {
        dispatch(searchFetch(search))
        console.log(search)
        setExpanded(true)
        setSearch(e.target.value)

    }

    function expand() {
        setExpanded(true);
    }

    function close() {
        setExpanded(false);
    }

    const handleLogout = () => {
        setLogin(" ")
        navigate("/")
        dispatch(removeInfo())
        localStorage.removeItem("logintoken")
        localStorage.removeItem("addressList")
        localStorage.removeItem("myOrder")
        localStorage.removeItem("orderList")
        localStorage.removeItem("cart")
        window.location.reload();
    }

    return (
        <>
            <div className='container-fluid topBar1' >
                <div className='border-bottom header-upper row'>
                    <div className='col-12 col-md-9 col-lg-9'>
                        <div className='topBarInfo'>
                            <div className=''><PiVan /> Order before 22:00. shipped today</div>
                            <div className=''><BsBoxSeam /> Free Delivery</div>
                            <div className=''>+35,000 customer rate us with 9.7</div>
                        </div>
                    </div>
                    <div className='col-12 col-12 col-md-3 col-lg-3 upper-right' >
                        <div className='topBarInfo2'>

                            <div className=''>
                                <Link to={`${loginToken ? '/user' : '/login'}`} >
                                    <BsPersonFill />
                                </Link>
                            </div>

                            <div id="google_translate_element" ></div>
                            <div>
                                {loginToken ?
                                    <div className='pointer' data-bs-toggle="modal" data-bs-target="#logOutModal" >log out</div>
                                    :
                                    <Link to='/login'>
                                        Login
                                    </Link>
                                }
                                <div className="modal fade" id="logOutModal" tabindex="-1" aria-labelledby="logOutModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content rounded-0">
                                            <div className="modal-header">
                                                <h1 className="modal-title heading fs2rem" id="logOutModalLabel">Log Out</h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                             Are you sure you want to log out?
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary rounded-0" data-bs-dismiss="modal">No</button>
                                                <button type="button" onClick={() => { dispatch(logout()); handleLogout() }} className="btn btn-dark rounded-0">Yes</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar border-bottom navbar-expand-lg">
                <div className="container-fluid">
                    <Link to='/' >
                        <img src={`${baseURL}/${config?.ecommerce_logo}`} className='header-logo' alt={config?.ecommerce_name} />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse row" id="navbarTogglerDemo01">
                        <div className='col-md-8 col-12'>
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <NavLink to='/'
                                    className={({ isActive }) =>
                                        (isActive ? "nav-link navColor" : "nav-link")}
                                    aria-current="page" >
                                    Home
                                </NavLink>
                                <li className="nav-item">
                                    <NavLink to='/aboutus'
                                        className={({ isActive }) =>
                                            (isActive ? "nav-link navColor" : "nav-link")}
                                    >
                                        About Us
                                    </NavLink>
                                </li>
                                <li className="mega-drop-down nav-item">
                                    <NavLink >Categories</NavLink>
                                    <div className="animated fadeIn mega-menu">
                                        <div className="mega-menu-wrap my-4">
                                            <div className="row">
                                                <div className="col-md-3 col-6">
                                                    <ul className="stander">
                                                        {categories?.slice(0, 4).map((category) => {
                                                            return (
                                                                <Link key={category.id} to='/shop'><li onClick={() => { dispatch(productsFetch(category.id)); dispatch(productTypeFetch(category.id)); dispatch(emptyAllProducts()) }}><a >{category.name}</a></li></Link>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <ul className="stander">
                                                        {categories?.slice(4, 8).map((category) => {
                                                            return (
                                                                <Link key={category.id} to='/shop'><li onClick={() => { dispatch(productsFetch(category.id)); dispatch(productTypeFetch(category.id)); dispatch(emptyAllProducts()) }}><a >{category.name}</a></li></Link>

                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <ul className="stander">
                                                        {categories?.slice(8, 12).map((category) => {
                                                            return (
                                                                <Link key={category.id} to='/shop'><li onClick={() => { dispatch(productsFetch(category.id)); dispatch(productTypeFetch(category.id)); dispatch(emptyAllProducts()) }}><a >{category.name}</a></li></Link>

                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <ul className="stander">
                                                        {categories?.slice(12).map((category) => {
                                                            return (
                                                                <Link key={category.id} to='/shop'><li onClick={() => { dispatch(productsFetch(category.id)); dispatch(productTypeFetch(category.id)); dispatch(emptyAllProducts()) }}><a >{category.name}</a></li></Link>

                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item" >
                                    <NavLink to='/shop' onClick={() => { dispatch(allProductsFetch(1)); dispatch(emptyProducts()) }}
                                        className={({ isActive }) =>
                                            (isActive ? "nav-link navColor" : "nav-link")}
                                    >
                                        Shop
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className='flex-r-r col-md-4 col-12'>
                            <div>
                                <form className="" role="search">
                                    <div className='position-relative'>
                                        <input className="form-control searchBar" ref={inputref} value={search} onChange={(e) => { handleSearch(e) }} type="search" placeholder="Search" aria-label="Search" />
                                        {expanded ? (
                                            <div className={`searchRes`} onFocus={expand} onBlur={close} >
                                                <ul>
                                                    {searchProducts && (searchProducts?.map((products) => {
                                                        return (
                                                            <li>
                                                                <Link to='/single-product' onClick={() => { dispatch(singleProductFetch(products.id)); close() }}>
                                                                    <div className='row'>
                                                                        <div className='col-md-3 pe-0 col-3'>
                                                                            <img src={`${baseURL_search}/${products?.image[0]}`} className='img-fluid' />
                                                                        </div>
                                                                        <div className='col-md-9 col-9'>
                                                                            {products.name} <br />
                                                                            <b>{config?.currency_symbol}{products.price}</b>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        )
                                                    }))}

                                                </ul>
                                            </div>
                                        ) : null}
                                        <button className="btn searchIcon" onClick={() => { console.log(search) }} type="button"><BsSearch /></button>
                                    </div>
                                </form>
                            </div>

                            <div>
                                <button className="btn position-relative" type="button"><Link to='/wishlist'><FiHeart /></Link>
                                    <span className="position-absolute cart-total top-2 start-80 px-1 translate-middle bg-danger border border-light rounded-circle">
                                        {(wishlistItems?.length) ? wishlistItems?.length : 0}
                                        <span className="visually-hidden">New alerts</span>
                                    </span>
                                </button>
                                <button className="btn position-relative" type="button"><Link to='/cart'>
                                    <BsHandbag /></Link>
                                    <span className="position-absolute cart-total top-2 start-80 px-1 translate-middle bg-danger border border-light rounded-circle">
                                        {(cartItems?.length) ? cartItems?.length : 0}
                                        <span className="visually-hidden">New alerts</span>
                                    </span>

                                </button>
                                <button className="btn position-relative" type="button"><Link to='/notification'>
                                    <FaRegBell /></Link>
                                    <span className="position-absolute cart-total top-2 start-80 px-1 translate-middle bg-danger border border-light rounded-circle">
                                        0
                                        <span className="visually-hidden">New alerts</span>
                                    </span>

                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <Chatbox /> */}
            </nav>
        </>
    )
}

export default MainHead
