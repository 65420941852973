import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";


const token = localStorage.getItem("logintoken")


const initialState = {
  status:"loading",
  wishlistItems: [],
  wishList: [],
  wishlistmsg: {},
}

const config = {
  headers: {
    "Authorization": `Bearer ${token}`,
    "content-type": "application/json; charset=UTF-8",
  }
}


export const getWishList = createAsyncThunk(
  "getWishList",
  async () => {
    try {
      const response = await axios.get(`/customer/wish-list`, config)
      console.log(response, "get wish")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)



export const addWishList = createAsyncThunk(
  "addWishList",
  async (id) => {
    console.log(id, 'wish id')
    const ID = JSON.stringify({ product_id: `${id}` })
    console.log(ID)
    try {
      const response = await axios.post(`/customer/wish-list/add`, ID, config)
      console.log(response, "added to wish")
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)

export const deleteWishList = createAsyncThunk(
  "deleteWishList",
  async (id) => {
    try {
      const response = await axios.delete(`/customer/wish-list/remove?product_id=${id}`, config)
      console.log(response, "deleted")
      window.location.reload(true)
      return response?.data
    } catch (error) {
      console.log(error.message)
    }

  }
)



const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {

    removeAll: (state) => {
      return {
        ...state,
        wishlistItems: [],
      }
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(addWishList.fulfilled, (state, action) => {
      console.log(action.payload, 'wishtextttttt')
      state.wishlistmsg = action.payload
      console.log(state.wishlistmsg, 'msg')
    }).addCase(getWishList.fulfilled, (state, action) => {
      state.wishList = action.payload
      state.wishlistmsg=[]
      state.status = 'fulfilled'
    }).addCase(deleteWishList.fulfilled, (state, action) => {
      console.log(action.payload, 'wishtextttttt')
      state.wishlistmsg = action.payload
    })
  }

})





export const { addToWishList, removeFromWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer