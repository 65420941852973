import React from 'react'
import { useSelector } from 'react-redux'
import { Rating } from 'react-simple-star-rating';
import img1 from '../assets/img/review.png'

const ProductReview = () => {

    const reviews = useSelector((state) => state.products.reviews)


    console.log(reviews, 'llllll')


    return (
        <div className='container' >
            <h2 className=''>Reviews({reviews?.length})</h2>
            <div className='col-xl-12 col-lg-12 col-md-12 mxH430' >
                <div className='container'>
                    {reviews.length !== 0 ?
                        reviews?.map((review) => {
                            return (
                                <div className='m-4 d-flex flex-direction-row' >
                                    <div className='mx-3' >
                                        <img src={img1} className='rounded-circle' />
                                    </div>
                                    <div>
                                        <Rating initialValue={review?.rating} size={20} readonly={true} />
                                        <h6>{review?.customer.f_name} {review?.customer.l_name}- <span className='date'>{review?.created_at.slice(0, 10)}</span></h6>                                    <p>{review.comment}</p>
                                    </div>
                                </div>
                            )
                        }) : <p>No reviews yet</p>
                    }              
                </div>
            </div>
        </div>
    )
}

export default ProductReview
