import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InputComponent from '../Components/InputComponent'
import { forgetPassword, login } from '../redux/features/authSlice'
import { addressListFetch, userInfoFetch } from '../redux/features/userSlice'
import { getWishList } from '../redux/features/wishlistSlice'


const ForgetPassword = () => {

    const [email, setEmail] = useState("")


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginToken = useSelector((state)=>state.auth.loginToken)
    const config = JSON.parse(localStorage.getItem("config"))
    const errors = useSelector((state)=>state.auth.forgetPasswordError)
    const baseURL = config?.base_urls?.ecommerce_image_url



    console.log(errors, "error")


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(JSON.stringify({email_or_phone:email}), 'emailllll')
        dispatch(forgetPassword(JSON.stringify({email_or_phone:email})))
    };


    const handleChange = (e) => {
        setEmail(e.target.value)
    };



    return (
        <div>
            <main className='signup__outer' >
                <img src={`${baseURL}/${config.ecommerce_logo}`} className="logo" alt={config.ecommerce_name} />
                <h3 className='text-center my-4'>Forget Password</h3>
                    <h6 className='text-center' >Please enter your email address to recieve a verification code</h6>
                <div className='w-5' >
                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className='row mt-4'>
                            <div className='col-xl-12 col-lg-12 col-md-12'>
                                <InputComponent
                                    value={email}
                                    onChange={handleChange}
                                    label='Email' type='email' id='email' name='email' />
                            </div>
                            {errors? errors.map((error)=><p className='error' >{error.message}</p>): null}                                                    
                        </div>
                        <div className='w-5' >
                            <button type="submit" 
                             className="signup__btn btn">Send</button>
                        </div>
                    </form> 
                </div>
            </main>
        </div>
    )
}

export default ForgetPassword;


