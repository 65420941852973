import React from 'react'
import chechMark from '../assets/img/checkmark.png'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { trackOrder } from '../redux/features/orderSlice'

const OrderPlaced = () => {
    const orderPlacedmsg = useSelector((state) => state?.order?.orderPlacedmsg)
    const dispatch = useDispatch()



    return (
        <div className='text-center my-5' >
            <img src={chechMark} width='100px' />
            <h5>{orderPlacedmsg.message}</h5>
            <p>Order ID:{orderPlacedmsg.order_id}</p>
                <Link to='/track-order'><button onClick={()=>{dispatch(trackOrder(orderPlacedmsg.order_id))}} className="signup__btn btn w-auto px-4" >Track Order</button></Link>
        </div>
    )
}

export default OrderPlaced
