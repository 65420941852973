import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { allProductsFetch } from '../redux/features/allProductSlice';

function Pagination() {
    const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 11;



  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div onClickCapture={()=>{dispatch(allProductsFetch(currentPage +1)) ; scrollToTop()}} className='mt-4' >
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={setCurrentPage}
          onClickCapture={scrollToTop}
        />
    </div>
  );
}

export default Pagination;