import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MyOrder, cancelOrder, orderList, orderMessage, trackOrder, updatePayment } from '../redux/features/orderSlice'
import { ImSad2 } from 'react-icons/im'
import { allProductsFetch } from '../redux/features/allProductSlice'
import { emptyProducts } from '../redux/features/productSlice'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify'
import { singleProductFetch } from '../redux/features/singleProductSlice'
import TrackOrder from './TrackOrder'


const Order = () => {
  const dispatch = useDispatch()
  const myOrder = useSelector((state) => state?.order?.myOrder)
  const myOrderList = useSelector((state) => state?.order?.orderList)
  const config = JSON.parse(localStorage.getItem("config"))
  const baseURL = config?.base_urls?.product_image_url
  const token = localStorage.getItem("logintoken")
  const status = useSelector((state) => state.order.status)
  const state = useSelector((state) => state?.order)



  const notify = () => toast(
    `Order canceled`, {
    autoClose: 3000,
    hideProgressBar: true,
  });


  useEffect(() => {
    state?.orderPlacedmsg?.message && dispatch(orderList())
  }, [state?.orderPlacedmsg?.message])

  useEffect(() => {
    state?.payment?.message && dispatch(orderList())
  }, [state?.payment?.message])


  useEffect(() => {
    dispatch(MyOrder(state?.orderPlacedmsg?.order_id))
    dispatch(orderList())
  }, [token])



  console.log(myOrder, 'sfskghkgfh')



  return (

    status !== 'fulfilled' ?
      <div className='loader mt-5'>
        <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
      </div> :
      <>

        {(myOrderList) ?
          <>
            {myOrderList?.length !== 0 ?
              <>
                <div>
                  <h3 className='my-3 heading'>Recent Orders</h3>
                  <div className='row'>
                    {
                      myOrderList?.map((order) => {
                        return (
                          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                            <div className='card mb-3 shadow p-1'>
                              <div className='row'>
                                <div className='col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9'>
                                  <table className='table orderTable'>
                                    <tr>
                                      <th>Order date</th>
                                      <td>:</td>
                                      <td>{order.created_at.slice(0, 10)}</td>
                                    </tr>
                                    <tr>
                                      <th>Order id</th>
                                      <td>:</td>
                                      <td>{order.id}</td>
                                    </tr>
                                    <tr>
                                      <th>Order status</th>
                                      <td>:</td>
                                      <td>{order.order_status}</td>
                                    </tr>
                                    <tr>
                                      <th>Order type</th>
                                      <td>:</td>
                                      <td>{order.order_type}</td>
                                    </tr>
                                    <tr>
                                      <th>Payment</th>
                                      <td>:</td>
                                      <td>{order.payment_method}</td>
                                    </tr>
                                    <tr>
                                      <th>Order amount</th>
                                      <td>:</td>
                                      <td>{config?.currency_symbol}{order.order_amount}</td>
                                    </tr>
                                  </table>
                                </div>
                                <div className='align-items-center col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xxl-3 flexCenter'>
                                  <Link to='/user/view-order' className='w-100'><button onClick={() => { dispatch(MyOrder(order.id)); dispatch(orderList()); dispatch(orderMessage(order.id)) }} type="button" class="btn btn-dark py-3 rounded-0 w-100">view Order</button></Link>
                                  {order.order_status === 'pending' ? <Link to='/track-order' className='w-100'><button onClick={() => { dispatch(trackOrder(order.id)) }} type="button" class="btn btn-dark py-3 rounded-0 w-100">Track Order</button></Link> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }).reverse()
                    }
                  </div>
                  <ToastContainer />
                </div>
              </>
              :
              <div className='container m-5 text-center' >
                <p className='heading' >No Order Found <ImSad2 className='sad-icon' /></p>
                <Link to='/shop' onClick={() => { dispatch(allProductsFetch(1)); dispatch(emptyProducts()) }} ><p className='slide-btn'>Shop Now</p></Link>
              </div>
            }
          </> :
          <div className='container m-5 text-center' >
            <p className='heading' >No Order Found <ImSad2 className='sad-icon' /></p>
            <Link to='/shop' onClick={() => { dispatch(allProductsFetch(1)); dispatch(emptyProducts()) }} ><p className='slide-btn'>Shop Now</p></Link>
          </div>
        }
      </>
   
  )
}

export default Order
