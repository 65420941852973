import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../axios'
import storage from "redux-persist/es/storage";


const initialState = {

    token:"",
    loginToken:"",
    SignupError: [],
    loginError:[],
    forgetPasswordError:[],

}

const token = localStorage.getItem("token")


const config = {
    headers: {
        "Authorization": "Bearer null",
        "content-type": "application/json; charset=UTF-8",
    }
}


const config1 = {
    headers: {
      "Authorization": `Bearer ${token}`,
      "content-type": "application/json; charset=UTF-8",
    }
  }


export const signup = createAsyncThunk(
    "signup",
    async (user) => {
        console.log(user, 'user')
        try {
            const response = await axios.post('/auth/registration', user, config)
            localStorage.setItem("token", response.data.token)
            return response.data;
        } catch (error) {
            return error.response.data.errors
        }
    });

export const login = createAsyncThunk(
    "login",
    async (user) => {
        try {
            const response = await axios.post('/auth/login', user, config)
            localStorage.setItem("logintoken", response.data.token)
            return response.data;
        } catch (error) {
            return error.response.data.errors
        }
    }
);

export const forgetPassword = createAsyncThunk(
    "forgetPassword",
    async (emailOrPhone) => {
        try {
            console.log(config, 'config')
            const response = await axios.post('/auth/forgot-password', emailOrPhone, config)
            console.log(response, 'forget password')
            return response.data;
        } catch (error) {
            return error.response.data.errors
        }
    }
);

export const verifyToken = createAsyncThunk(
    "verifyToken",
    async (data) => {
        try {
            const response = await axios.post('/auth/verify-token', data, config)
            console.log(response, 'verify token')
            return response.data;
        } catch (error) {
            return error.response.data.errors
        }
    }
);


export const resetPassword = createAsyncThunk(
    "resetPassword",
    async (data) => {
        try {
            const response = await axios.post('/auth/reset-password', data, config)
            console.log(response, 'reset password')
            return response.data;
        } catch (error) {
            return error.response.data.errors
        }
    }
);



const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.loginToken = ""
            localStorage.removeItem("logintoken")
            storage.removeItem('persist:root')
          },
          signout:(state)=>{
            state.token = ""
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signup.fulfilled, (state, action) => {
                    if(action.payload.token){
                        state.token = action.payload.token
                        state.SignupError = []
                        state.loginError=[]
                    }else{
                        state.SignupError = action.payload
                        state.token = ""
                        state.loginError=[]
                    }         
            })
            .addCase(login.fulfilled, (state, action) => {
                if(action.payload.token){
                    state.loginToken = action.payload.token
                    state.loginError = []
                }else{
                    state.loginError = action.payload
                    state.loginToken = ''
                }
            }).addCase(forgetPassword.fulfilled, (state, action) => {
                state.forgetPasswordError = action.payload
            })
    },
})



export const { logout , signout } = authSlice.actions;
export default authSlice.reducer