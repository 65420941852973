import React, { useEffect, useRef, useState } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { RxCross1 } from 'react-icons/rx'
import { useDispatch, useSelector } from 'react-redux'
import { addCart, fetchCart, removeCart, } from '../redux/features/cartSlice'
import { Link, useNavigate } from 'react-router-dom'
import { ImSad2 } from 'react-icons/im'
import { BsArrowLeft, BsSignNoRightTurn } from 'react-icons/bs'
import { allProductsFetch } from '../redux/features/allProductSlice'
import { emptyProducts } from '../redux/features/productSlice'
import { getCoupon } from '../redux/features/userSlice'
import 'react-edit-text/dist/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ShoppingCart = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [quantity, setQuantity] = useState("")
    const [editQuantity, setEditQuantity] = useState({
        id: '',
        state: false
    })
    const config = JSON.parse(localStorage.getItem("config"))
    const baseURL = config?.base_urls?.product_image_url
    const state = useSelector((state) => state?.cart)
    const cartItems = JSON.parse(localStorage.getItem("cart"))
    const cartSubtotal = useSelector((state) => state.cart.cartTotalAmount)
    const coupon = useSelector((state) => state.userInfo.coupon)
    const loginToken = localStorage.getItem("logintoken")




    const handleEditQuantity = (id) => {
        setEditQuantity({
            id,
            state: true
        })
    }


    const notify = () => {
          toast('Removed from cart!', {
            autoClose: 3000,
            hideProgressBar: true,
          });
         
      };


    const handleBlur = (id, type) => {
        if (quantity == "" || quantity === 0) {
            setQuantity(1)
        dispatch(addCart(JSON.stringify({ product_id: id, quantity: 1 })))
        }

        dispatch(addCart(JSON.stringify({ product_id: id, quantity: quantity })))
    }


    // const getTotal = cartItems?.reduce((total, currentItem) => {
    //     return total + currentItem.product_data.price * currentItem.quantity
    // }, 0)


    const getTotal = cartItems?.reduce((total, currentItem) => {
        // Check if the item is in stock before including it in the total
        if (!(currentItem.product_data.total_stock < currentItem.quantity)) {
          return total + currentItem.product_data.price * currentItem.quantity;
        }
        // If the item is out of stock, do not include it in the total
        return total;
      }, 0);
      



    useEffect(() => {
        state?.state?.message && dispatch(fetchCart())
        console.log(state?.state?.message, 'yolo')
        dispatch(fetchCart())
    }, [state?.state?.message])

    useEffect(() => {
        dispatch(fetchCart())
        dispatch(getCoupon())
    }, [])


    return (
        <main className='container my-4'>
            {cartItems?.length === 0 || (!loginToken)  ?
                <div className='text-center' >
                    <p className='heading' >Cart Is Empty<ImSad2 className='sad-icon' /></p>
                    <Link to='/shop' onClick={() => { dispatch(allProductsFetch(1)); dispatch(emptyProducts()) }} ><p className='slide-btn'>Shop Now</p></Link>
                </div>
                :
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='col-md-10' >
                            <h3 className="heading mb-4">Shopping Cart</h3>
                            {cartItems?.map((item) => {
                                return (
                                    <div className='row mb-3 cart_item' key={item.product_data.id}>
                                        <div className='col-md-2 col-3 my-auto'>
                                            <img src={`${baseURL}/${JSON.parse(item?.product_data.image)[0].replace(/"[]/g, '')}`} className='cart-img' alt={item.name} />
                                        </div>
                                        <div className='col-md-7 col-5'>
                                            <p className='mt-2 f12-m ml-2'>{item.product_data.name}</p>
                                            <h5 className='f16-m'>{config?.currency_symbol}{item.product_data.price}</h5>
                                        </div>
                                        <div className='col-md-3 col-4'>
                                            <h6 className='mt-2 align-content-center d-flex justify-content-end mt-2'> &nbsp;<RxCross1 className='pointer' onClick={() => { dispatch(removeCart(JSON.stringify({ product_id: item.product_data.id }))); notify()}} /></h6>
                                            <div className='quantity'>
                                                <span className='fs-6'>Quantity</span>
                                                <input
                                                    onFocus={() => setQuantity("")}
                                                    onBlur={() => handleBlur(item.product_data.id)}
                                                    type="number"
                                                    min="1"
                                                    name="quantity"
                                                    className="w-100 text-center"
                                                    value={
                                                        editQuantity.state && editQuantity.id === item.product_data.id ? quantity : item.quantity
                                                    }
                                                    onClick={() => handleEditQuantity(item.product_data.id)}
                                                    onChange={(e) => setQuantity(e.target.value)}
                                                />
                                            </div>
                                                {(item.quantity > item.product_data.total_stock)? <p className='error' >Out of stock</p>: null }
                                        </div>
                                        <ToastContainer />
                                    </div>
                                )
                            })}
                            <span className='pointer mt-5' onClick={() => navigate(-1)}><BsArrowLeft /> Continue shopping</span>
                        </div>
                    </div>
                    <div className="col-md-4" >
                        <div className='f12-m mt-4' >
                            <div className='my-4 mt-5' >
                                <div className='d-flex flex-row justify-content-between'>
                                    <h6>Subtotal</h6>
                                    <p>{config?.currency_symbol}{getTotal?.toFixed(2)}</p>
                                </div>
                                <div className='d-flex flex-row justify-content-between'>
                                    <h6>Shipping Charges</h6>
                                    {getTotal<50? <p>{config?.currency_symbol}6.50</p>: <p>{config?.currency_symbol}0</p>}
                                    {/* <p>{config?.currency_symbol}{getTotal < 50? "6.50":"0"}</p> */}
                                </div>
                                <div className='d-flex flex-row justify-content-between'>
                                    <h6>Coupon</h6>
                                    {coupon?.length == 0 ? <p>not available</p> : <p></p>}
                                </div>
                            </div>
                            <div className='border-top ' >
                                <div className='d-flex flex-row justify-content-between mt-4 '>
                                    <h6>Total</h6>
                                    {getTotal < 50? <h6>{config?.currency_symbol}{(getTotal+6.50)?.toFixed(2)}</h6> : <h6>{config?.currency_symbol}{(getTotal)?.toFixed(2)}</h6>}
                                    {/* <h6>{config?.currency_symbol}{getTotal?.toFixed(2)}</h6> */}
                                </div>
                                <div className='text-center' >
                                    <Link to='/checkout'><p className='slide-btn'>Check out</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </main>
    )
}

export default ShoppingCart
