import React, { useEffect } from 'react'
import Homecarousal from '../Components/Homecarousal'
import HomeProduct from '../Components/HomeProduct'
import HomeCategory from '../Components/HomeCategory'
import Banner from '../Components/Banner'
import { batch, useDispatch, useSelector } from 'react-redux'
import { categoriesFetch, bannerFetch, configFetch, notificationFetch } from '../redux/features/configSlice'
import { allProductsFetch } from '../redux/features/allProductSlice'
import { getWishList } from '../redux/features/wishlistSlice'
import ChatButton from '../Components/ChatButton'




const Home = () => {
  const dispatch = useDispatch()
  const banner = JSON.parse(localStorage.getItem("banner"))
  const token = localStorage.getItem("logintoken")
  const logintoken = useSelector((state) => state?.auth?.loginToken)


  const min = 1;
  const max = 10;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  console.log(randomNumber);



  useEffect(() => {
    batch(() => {
      dispatch(allProductsFetch(randomNumber))
      dispatch(categoriesFetch())
      dispatch(configFetch())
      dispatch(bannerFetch())
      dispatch(getWishList())
      dispatch(notificationFetch())
    });
  }, [token, logintoken])

  return (
    <div>
      <Homecarousal />
      <HomeProduct />
      <Banner images={banner?.slice(2, 3)} heading='Shop Now' subHeading='Olaplex' imgStyle='olaplex-banner' columnStyle='shopBanner' imageSubHeading='OLAPLEX is the miracle revolution the hair industry has been waiting for.' />
      <Banner images={banner?.slice(3, 7)} columnNo={3} heading='In The Spotlight' subHeading='Our Most Loved Products' imgStyle='banner-img' imageSubHeading='Shop Now' columnStyle='banner-div' />
      <Banner images={banner?.slice(7)} columnNo={6} heading='Skin Care & Hair Care' subHeading='For perfect skin and flawless hair' imgStyle='banner3-img' containerStyle='banner3-outer' columnStyle='banner3-div' />
      <HomeCategory />
      {/* <ChatButton /> */}
    </div>
  )
}

export default Home
