import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RxCross1 } from 'react-icons/rx'
import { ImSad2 } from 'react-icons/im'
import { singleProductFetch } from '../redux/features/singleProductSlice'
import { deleteWishList, getWishList} from '../redux/features/wishlistSlice'
import { allProductsFetch } from '../redux/features/allProductSlice'
import { emptyProducts, getProductReview } from '../redux/features/productSlice'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { addCart } from '../redux/features/cartSlice'


const WishlistPage = () => {

  const dispatch = useDispatch()
  const config = JSON.parse(localStorage.getItem("config"))
  const baseURL = config?.base_urls?.product_image_url
  const wishlistItems = useSelector((state) => state?.wishlist?.wishList)
  const status = useSelector((state) => state?.wishlist?.status)
  const loginToken = localStorage.getItem("logintoken")


  const notify1 = () => {
    if (loginToken) {
      toast(`Successfully added to cart!`, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      toast("You are not logged in", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const notify = () => toast(
    `Removed from wishlist`, {
    autoClose: 3000,
    hideProgressBar: true,
  });




  useEffect(() => {
    dispatch(getWishList())
  }, [])

  const reload=()=>{
    window.location.reload(true)
  }



  return (
    <div>
      <div className='container' >
        <div className='row'>
          {wishlistItems?.length ?
            <div className='grid_div' >

              {wishlistItems.map((item) => {
                return (

                  <div className='row p-4 text-center' key={item.product_data?.id} >
                    <div className='col-md-4 col-lg-4 col-xl-4' >
                      <Link onClick={() => {dispatch(singleProductFetch(item.product_data?.id)); dispatch(getProductReview(item.product_data?.id))}} to='/single-product'><img src={`${baseURL}/${JSON.parse(item.product_data?.image)[0].replace(/\"/g, '')}`} alt={item.product_data?.name} className='img-fluid wishlist-img' /></Link>
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-6'>
                      <h6>{item.product_data?.name}</h6>
                      <p >{config?.currency_symbol}{item.product_data?.price}</p>
                      <Link onClick={() => dispatch(singleProductFetch(item.product_data?.id))} to='/single-product'><div dangerouslySetInnerHTML={{ __html: item.product_data?.description }} className='description' /></Link>
                      {(item.product_data?.total_stock <= 0)? <p className='slide-btn text-center error'  >Out Of Stock</p>: <p className='slide-btn text-center' onClick={() => { dispatch(addCart(JSON.stringify({product_id:item.product_data?.id,quantity:1 }))); notify1() }}>Add To Cart</p>}
                    </div>
                    <div className='col-md-2 col-lg-2 col-xl-2' >
                      <RxCross1 onClick={() => { dispatch(deleteWishList(item.product_data?.id));notify() }} />
                    </div>
                  </div>

                )
              })}
              <ToastContainer />
            </div>
            :
            (

              status === 'loading' ? <div className='loader mt-5'>
                <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
              </div> : <div className='container m-5
                text-center' >
                <p className='heading' >No Products in Wishlist<ImSad2 className='sad-icon' /></p>
                <Link to='/shop' onClick={() => { dispatch(allProductsFetch(1)); dispatch(emptyProducts()) }} ><p className='slide-btn'>Add Now</p></Link>
              </div>

            )

          }

        </div>
      </div>
    </div>
  )
}

export default WishlistPage
