import React from 'react'
import { useDispatch} from 'react-redux';
import { productsFetch } from '../redux/features/productSlice';
import { Link } from 'react-router-dom';
import { emptyAllProducts, productTypeFetch} from '../redux/features/allProductSlice';


const Banner = ({
  heading,
  subHeading,
  images,
  imgStyle,
  columnNo,
  columnStyle,
  containerStyle,
  imageSubHeading
}) => {
  const dispatch = useDispatch()
  const config = JSON.parse(localStorage.getItem("config"))
  const baseURL = config?.base_urls?.banner_image_url


  const scrollToTop = () => {
    window.scrollTo(0, 0)
}


  return (

    <div className={`container-fluid ${containerStyle}`} >
      <h3 className='text-center my-2 heading'>{heading}</h3>
      <p className='text-center mb-5' >{subHeading}</p>
      <div className='row'>
        {images?.map((img) => (
          <div className={`col-md-${columnNo} ${columnStyle}`} key={img.category_id} >
            <Link to='/shop' onClick={()=>{dispatch(productsFetch(img.category_id)); dispatch(emptyAllProducts()) ; scrollToTop(); dispatch(productTypeFetch(img.category_id))}} >
            <img src={`${baseURL}/${img.image}`} alt='product Image' className={imgStyle} />
            <p className='text-center'>{imageSubHeading}</p>
          </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Banner;
