import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactLoading from 'react-loading';
import { SlCalender } from 'react-icons/sl';
import { MyOrder, cancelOrder, orderList, updatePayment } from '../redux/features/orderSlice';
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom';



const ViewOrder = () => {


    const token = localStorage.getItem("logintoken")
    const state = useSelector((state) => state?.order)
    const myOrder = JSON.parse((localStorage.getItem("myOrder")))
    const myOrderList = useSelector((state) => state?.order?.orderList)
    const status = useSelector((state) => state.order.status);
    const config = JSON.parse(localStorage.getItem("config"));
    const getOrdreMsg = useSelector((state) => state?.order?.getOrderMsg)
    const baseURL = config?.base_urls?.product_image_url
    const dispatch = useDispatch()


    const notify = () => toast(
        `Order canceled`, {
        autoClose: 3000,
        hideProgressBar: true,
    });

    console.log(getOrdreMsg.messages, 'message ')

    useEffect(() => {
        state?.orderPlacedmsg?.message && dispatch(orderList())
    }, [state?.orderPlacedmsg?.message])

    useEffect(() => {
        state?.payment?.message && dispatch(orderList())
    }, [state?.payment?.message])


    useEffect(() => {
        dispatch(MyOrder(state?.orderPlacedmsg?.order_id))
        dispatch(orderList())
    }, [token])



    console.log(myOrder, 'my order')
    console.log(myOrderList, 'list order')


    return (
        <>
            {

                status !== 'fulfilled' ?
                    <div className='loader mt-5'>
                        <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
                    </div>
                    :
                    <div>{myOrderList?.length === 0 ? (
                        <div className='loader mt-5'>
                            <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
                        </div>
                    ) : (
                        myOrder && myOrder.length > 0 ? (
                            myOrderList.map((order) =>
                                (order?.id === myOrder[0]?.order_id) ?
                                    <div className='container'>
                                        <h3 className='my-3 heading'>Order Details</h3>
                                        <div>
                                            {getOrdreMsg?.messages?.map((message) => {
                                                return (
                                                    <p className='message' >{message}</p>
                                                )
                                            })}
                                        </div>
                                        <div className='row my-4'>
                                            <div className='col-8 col-md-8 col-lg-8'>
                                                <h6>Order Id: <b>{order?.id}</b></h6>
                                                <h6>Order Status: <b>{order?.order_status}</b></h6>
                                            </div>
                                            <div className='col-4 col-md-4 col-lg-4 text-end'>
                                                <p className='order-date' ><SlCalender /> {order?.created_at.slice(0, 10)}</p>
                                                {order?.order_status === "delivered" ? <p className='text-end'><Link to={`/user/review/${order?.id}/null/deliveryReview`}><button className='btn btn-dark rounded-0 fs12' >Delievry Man Review</button></Link></p>
                                                    : null}
                                            </div>
                                            <div className='col-12 col-md-12 col-lg-12'>
                                                <h5 className='mb-3 pb-2 text-center border-bottom'>Payment Detail</h5>
                                            </div>
                                            <div className='col-8 col-md-8 col-lg-8'>
                                                <h6>Status: <b>{order?.payment_status}</b></h6>
                                                <h6>Method: <b>{order?.payment_method}</b></h6>
                                            </div>
                                            <div className='col-4 col-md-4 col-lg-4 text-end'>
                                                <h5></h5>
                                                {(order?.payment_method == "digital_payment" && order?.order_status == "pending") ?
                                                    <>
                                                        <button type="button" class="btn btn-dark py-3 rounded-0" data-bs-toggle="modal" data-bs-target="#paymentModal">
                                                            Update Payment
                                                        </button>
                                                        <div class="modal fade text-start" id="paymentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <h1 class="modal-title fs12" id="exampleModalLabel">Update Payment</h1>
                                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        Do you Want to Update Payment method to Cash On Delivery?
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="button" class="btn btn-danger rounded-0" data-bs-dismiss="modal">Close</button>
                                                                        <button type="button" data-bs-dismiss="modal" onClick={() => {
                                                                            console.log(order.id, 'order upatee');
                                                                            dispatch(updatePayment(JSON.stringify({
                                                                                order_id: order.id,
                                                                                payment_method: "cash_on_delivery"
                                                                            })))
                                                                        }} class="btn btn-dark rounded-0">Yes</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null}
                                            </div>
                                        </div>
                                        <h5 className='mb-3 pb-2 text-center border-bottom'>Product Detail</h5>
                                        <table className='table border-0 border-bottom'>
                                            <tbody>
                                                {myOrder.map((singleOrder) => {
                                                    return (
                                                        <tr>
                                                            <td width='80px'>

                                                                <img src={`${baseURL}/${singleOrder.product_details?.image[0]}`} alt='product-Img' className='orderTableImg' />
                                                            </td>
                                                            <td>{singleOrder.product_details?.name}<br /> <b>{config?.currency_symbol}{singleOrder?.product_details.price}</b></td>
                                                            <td className='text-end'>Quantity: <b>{singleOrder?.quantity}</b></td>
                                                            {order?.order_status === 'delivered' ? <td className='text-end'><Link to={`/user/review/${singleOrder.order_id}/${singleOrder.product_id}/productReview`}><button className='btn btn-dark rounded-0 fs12' >Add Review</button></Link></td>
                                                                : null}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        <table className='table border-0 border-bottom'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Shipped to:<br />
                                                        <b>{order.customer.f_name} {order.customer.l_name}</b>
                                                    </td>
                                                    <td className='text-end'>Total: <b>{config?.currency_symbol}{order.order_amount}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='text-end' data-bs-toggle="modal" data-bs-target="#cancelModal" >
                                            <button type="button" class="btn btn-dark mt-3 py-3 text-center rounded-0 w-50">cancel Order</button>
                                        </div>
                                        <div class="modal fade text-start" id="cancelModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content rounded-0">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title heading fs2rem" id="exampleModalLabel">Cancel Order</h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        Do you Want to cancel your order?
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" data-bs-dismiss="modal" onClick={() => { dispatch(cancelOrder(order.id)); notify() }} class="btn btn-dark rounded-0">Yes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ToastContainer />
                                    </div>
                                    :
                                    null
                            )
                        ) : (
                            <div className='loader mt-5'>
                                <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
                            </div>
                        )
                    )}
                    </div >
            }
        </>
    )
}

export default ViewOrder
