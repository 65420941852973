import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";


const initialState={
    singleProduct:{},
    status:"loading"
}

export const singleProductFetch = createAsyncThunk(
  "singleProductFetch" ,
  async(id)=>{
    console.log(id, 'selected serch')
    const response= await axios.get(`/products/details/${id}`)
    localStorage.setItem("singleProduct", JSON.stringify(response?.data))
    return response?.data
      }
)

const singleProductSlice = createSlice({
    name:"singleProduct",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(singleProductFetch.pending,(state, action) => {
            state.status = "pending";
          })
          .addCase(singleProductFetch.fulfilled,(state, action) => { 
           state.singleProduct = action.payload
           state.status = "fulfilled"

          })
          .addCase(singleProductFetch.rejected,(state, action) => {
            state.status = "rejected";        
          })         
      },
})




export default singleProductSlice.reducer