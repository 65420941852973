import React, { useEffect, useState } from 'react'
import InputComponent from './InputComponent';
import { Rating } from 'react-simple-star-rating'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { delieverymanReview, submitProductReview } from '../redux/features/productSlice';
import { AiOutlinePlus } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const Review = () => {

    const dispatch = useDispatch()
    const reviewMsg = useSelector((state)=>state?.products?.reviewMsg) 
    const { productId, orderId ,reviewType} = useParams()

    const [starRating, setStarRating] = useState(0)
    const [selectedImages, setSelectedImages] = useState([]);
    const [reviewData, setReviewData] = useState({
        product_id: "",
        rating: "",
        comment: "",
        delivery_man_id: "",
        attachment: "",
        order_id: " "
    });

    // useEffect(() => {
    //     console.log(selectedImages, 'images');
    //   }, [selectedImages]);


    const FillDetail = () => toast(`Please Fill All Details`, {
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });


    const reviewSubmit = () => toast(`successfully review submitted!`, {
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const handleRating = (rate) => {
        setStarRating(rate)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setReviewData((prevFormData) => ({ ...prevFormData, [name]: value }));    
    };

    const handleImage=(e)=>{
        const files = e.target.files;
        const imageArray = Array.from(files);
        setSelectedImages([...selectedImages, ...imageArray]);
        console.log(URL.createObjectURL(...selectedImages), 'imagess')
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const userReview = { ...reviewData, rating: starRating, product_id: productId, order_id: orderId}
        
            if(reviewType=='deliveryReview'){
                if (userReview?.rating !== "" && userReview?.comment !== "" && userReview?.delivery_man_id!==""){
                    dispatch(delieverymanReview(JSON.stringify(userReview)))
                    console.log('delivery')
                }else{
                    FillDetail();
                }
            }else{
                if (userReview?.rating !== "" && userReview?.comment !== ""){
                    dispatch(submitProductReview(JSON.stringify(userReview)))
                    console.log("product")
                    reviewSubmit();
                }else{
                    FillDetail();
                }
            }
       
        console.log(userReview, 'reviewwwwww')
        setReviewData({
            product_id: "",
            rating: "",
            comment: "",
            delivery_man_id: "",
            attachment: "",
            order_id: " "
        })
        setStarRating(0)
    };

    return (
        <div className='container' >
            <div className='row mt-4'>
                <div className='col-mx-12 col-lg-12 col-md-12' >
                    <h4 className='heading mb-3' >{reviewType=='deliveryReview'? "Delivery Man": "Product"} Review </h4>
                    <form onSubmit={handleSubmit} >
                        <div className='col-xl-12 col-lg-12 col-md-12 mb-3'>
                            {reviewType=='deliveryReview'? 
                            <div className="form-floating mb-3">
                            <input onChange={handleChange} value={reviewData.delivery_man_id} className="form-control" id="delivery_man_id" name='delivery_man_id' placeholder=''></input>
                            <label for="delivery_man_id">Delivery Man ID</label>
                        </div>
                            : null}
                            {/* <label htmlFor='productImg' className='plusIcon'>
                                <div>
                                    <AiOutlinePlus />
                                    <br />
                                    <span className=''>Add Image</span>
                                </div>
                            </label>
                            <input type='file' multiple id='productImg' onChange={handleImage} className='d-none' />
                            {selectedImages.length!==0? selectedImages.map((img)=>{
                                return(
                                    <img src={URL.createObjectURL(img)} className='reviewImg' />
                                )
                            }):null} */}
                            <p className='rating'>Your Rating</p>
                            <Rating onClick={handleRating} initialValue={starRating} size={20} readonly={false} />
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12'>
                            <div className="form-floating mb-3">
                                <textarea onChange={handleChange} value={reviewData.comment} className="form-control h10" id="comment" name='comment' placeholder=''></textarea>
                                <label for="comment">Add Comment...</label>
                            </div>
                            <button type='submit' className='btn btn-dark w-50 rounded-0'>Submit</button>
                        </div>
                    </form>
                </div>
               
            </div>
            <ToastContainer />
        </div>
    )
}

export default Review
