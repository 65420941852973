import React from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
import { productsFetch } from '../redux/features/productSlice'
import { emptyAllProducts , productTypeFetch } from '../redux/features/allProductSlice'


function Homecarousal() {
  const dispatch = useDispatch()
  const banner = JSON.parse(localStorage.getItem("banner"))
  const config = JSON.parse(localStorage.getItem("config"))
const baseURL = config?.base_urls?.banner_image_url


  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide" >
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div className="carousel-inner">
          {banner?.slice(0, 2).map((item) => {
            return (
              <Link to='/shop' key={item.category_id} onClick={()=>{dispatch(productsFetch(item.category_id));dispatch(emptyAllProducts());dispatch(productTypeFetch(item.category_id))}} >
                <div className="carousel-item active">
                  <img src={`${baseURL}/${item.image}`} alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className='heading'>{item.title}</h5>
                    <p>Shop our great welcome back deals to take care of your hair down to the last detail</p>
                  </div>
                </div>
              </Link>
                )
    })}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

    </>
  )
}

export default Homecarousal;