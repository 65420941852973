import React from 'react'
import ShopMain from '../Components/ShopMain'
import Banner from '../Components/Banner'
import { useSelector } from 'react-redux'



const Shop = () => {
  const banner = JSON.parse(localStorage.getItem("banner"))

  return (
    <div>
      <ShopMain />
      <Banner images={banner.slice(7)} columnNo={6} heading='Skin Care & Hair Care' subHeading='For perfect skin and flawless hair' imgStyle='banner3-img' containerStyle='banner3-outer' columnStyle='banner3-div'  />
    </div>
  )
}

export default Shop
