import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";


const initialState = {
    userInfo: {},
    address:[],
    deleted:[],
    addressList:[],
    status: "loading",
    coordinates:{
        lat:null,
        lng:null,
    },
    coupon:[]
}

const token = localStorage.getItem("logintoken")
console.log(token, 'ken')

const config = {
    headers: {
        "Authorization": `Bearer ${token}`,
        "content-type": "application/json; charset=UTF-8",
    }  
}

export const userInfoFetch = createAsyncThunk(
    "userInfoFetch",
    async (token ) => {
        try {
            const response = await axios.get(`/customer/info`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "content-type": "application/json; charset=UTF-8",
                }
            })
            console.log(response, 'user')
            localStorage.setItem("user", JSON.stringify(response?.data))
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)



export const addressListFetch = createAsyncThunk(
    "addressListFetch",
    async()=> {
            const response = await axios.get(`/customer/address/list`, config)
            localStorage.setItem("addressList",JSON.stringify(response?.data))
            return response?.data
    }
)


export const addressAddFetch = createAsyncThunk(
    "addressAddFetch",
    async (address) => {
        try {
            const response = await axios.post(`/customer/address/add`, address, config)
            console.log(response, 'address')
            return response?.data
        } catch (error) {
            alert("please fill all fields in address")
        }

    }
)


export const addressDelete = createAsyncThunk(
    "addressDelete",
    async (Id) => {
        try {
            const response = await axios.delete(`/customer/address/delete?address_id=${Id}`,config)
            console.log(response,'message')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)

export const addressUpdate = createAsyncThunk(
    "addressUpdate",
    async (address,id) => {
        const address_update = JSON.parse(address)
        console.log(address_update,'adressss')
        console.log(address_update.id, 'idddd91')
        try {
            const response = await axios.put(`/customer/address/update/${address_update.id}`,address,config)
            console.log(response,'update meee')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)


export const updateProfile = createAsyncThunk(
    "updateProfile",
    async (user) => {
        try {
            const response = await axios.put(`customer/update-profile`,user,config)
            console.log(response,'updated')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)

export const deleteAccount = createAsyncThunk(
    "deleteAccount",
    async (Id) => {
        console.log(config)
        try {
            const response = await axios.delete(`/customer/remove-account?user_id=${Id}`,config)
            console.log(response,'deleteddd')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)

export const getCoupon = createAsyncThunk(
    "getCoupon",
    async () => {
        try {
            const response = await axios.get(`/coupon/list`,config)
            console.log(response,'coupon')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)


export const getDistance = createAsyncThunk(
    "getDistance",
    async () => {
        try {
            const response = await axios.get(`http://hairforfree.com/api/api/v1/mapapi/distance-api?origin_lat=22.77874&origin_lng=75.57645&destination_lat=229888&destination_lng=75.323233&key=AIzaSyC-94El9frgOdBJdF8UnvFYWkBfTqTyp5Y`)
            console.log(response,'distance')
            return response?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)





const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        removeInfo: (state) => {
            state.userInfo = {}
        },
        fetchLatLng:(state, action)=>{

            console.log(action.payload,"latitudeeeee")
            let geoCoordinates = {
                ...state.coordinates,
                lat: action.payload.lat,
                lng: action.payload.lng
            }
            console.log(geoCoordinates, 'coords')
        state.coordinates = geoCoordinates
        console.log(state.coordinates, 'state coord')
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userInfoFetch.pending, (state, action) => {
            state.status = "pending";
        })
            .addCase(userInfoFetch.fulfilled, (state, action) => {
                state.userInfo = action.payload
                state.status = "fulfilled"
                state.deleted = {}
                console.log(state.userInfo, "userInfo")
            })
            .addCase(userInfoFetch.rejected, (state, action) => {
                state.status = "rejected";
            })
            .addCase(addressAddFetch.fulfilled, (state, action) => {
                state.address = action.payload;
                console.log(state.address)
            }).addCase(addressDelete.fulfilled, (state, action) => {
                state.address = action.payload
            }).addCase(addressListFetch.fulfilled, (state, action) => {
                state.addressList = action.payload
                state.address = {}
            }).addCase(getCoupon.fulfilled, (state, action) => {
                state.coupon= action.payload
            }).addCase(addressUpdate.fulfilled, (state, action) => {
                state.address = action.payload
            }).addCase(deleteAccount.fulfilled, (state, action) => {
                state.deleted = action.payload
            }).addCase(updateProfile.fulfilled, (state, action) => {
                state.deleted = action.payload
            })
    },
})


export const { removeInfo ,fetchLatLng} = userInfoSlice.actions;
export default userInfoSlice.reducer