import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAccount, removeInfo, updateProfile, userInfoFetch } from '../redux/features/userSlice'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import userProfile from '../assets/img/userProfile.jpg'
import InputComponent from './InputComponent'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { logout } from '../redux/features/authSlice'
import { configFetch } from '../redux/features/configSlice'
import ReactLoading from 'react-loading';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'



const Profile = () => {
    const [image, setImage] = useState(null);
    const [value, setValue] = useState()
    const statee = useSelector((state) => state?.userInfo)
    const [editProfile, SetEditProfile] = useState({
        f_name: "",
        l_name: "",
        phone: "",
        password: "",
    })


    useEffect(() => {
        statee?.deleted?.message && dispatch(userInfoFetch())
        console.log(statee?.deleted?.message, 'yolo')
    }, [statee?.deleted?.message])

    const handleChange = (event) => {
        const { name, value } = event.target;
        SetEditProfile((prevFormData) => ({ ...prevFormData, [name]: value }));
    };


    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
           reader.readAsDataURL(file);
        });
    }


    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const base64 = await convertToBase64(file);
                setImage(base64);
            } catch (error) {
                console.error('Error converting to base64:', error);
            }
        }
    };









    // const handleImage = (e) => {
    //     // setImage(event.target.files[0]);
    //     // if (event.target.files && event.target.files[0]) {
    //     //     let img = event.target.files[0];
    //     //     setImage({
    //     //       image: URL.createObjectURL(img)
    //     //     });
    //     //   }
    //     // setImage({
    //     //           image: event.target.files[0]
    //     //         });


    //     const file = e.target.files[0];
    //     const base64 = convertToBase64(file);
    //     setImage(base64);

    // }


    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updateProfile(JSON.stringify({ ...editProfile, image: image,phone:value})))
        console.log(JSON.stringify(JSON.stringify({ ...editProfile, image: image, phone:value })))
        console.log(image)

        SetEditProfile({
            f_name: "",
            l_name: "",
            phone: "",
            password: "",
        })

        setValue("")

    };


    const ref = useRef()

    const token = localStorage.getItem("logintoken")
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo.userInfo)
    const deleteMsg = useSelector((state) => state.userInfo.deleted)
    const config = JSON.parse(localStorage.getItem("config"))
    const state = useState((state) => state?.userInfo)
    const baseURL = config?.base_urls?.customer_image_url



    console.log(state, 'stateee')


    const handleLogout = () => {
        dispatch(removeInfo())
        localStorage.removeItem("logintoken")
    }


    useEffect(() => {
        dispatch(userInfoFetch(token))
        dispatch(configFetch())
    }, [token, deleteMsg.message])

    return (
        <div className='container user-div mt-3' >

            {state === 'pending' ?
                <div className='loader'>
                    <ReactLoading type='spokes' color='#000' height="50px" width='50px' />
                </div> :
                <>
                    <h3 className='text-center heading' >User Profile</h3>
                    <div className='row mt-4'>
                        <div className='border col-md-9 mx-auto p-4 rounded-3 shadow-lg'>
                            <img src={userProfile} alt='user-image' className='userImg' />
                            <div className='col-md-9 col-lg-9 mx-auto'>
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <b>First Name:</b>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        {userInfo?.f_name}
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <b>Last Name:</b>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        {userInfo?.l_name}
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <b>Phone:</b>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        {userInfo?.phone}
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <b>Email:</b>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        {userInfo?.email}
                                    </div>
                                </div>
                                <button type='button' data-bs-toggle="modal" data-bs-target="#staticBackdrop" className='btn btn-dark w-auto'>Edit &nbsp;<MdOutlineModeEditOutline /> </button>
                                <button type="button" className="btn btn-danger w-auto ms-2" data-bs-toggle="modal" data-bs-target="#deleteAccountModal">
                                    Delete&nbsp;<RiDeleteBin5Line />
                                </button>
                            </div>
                            <div className="modal fade" id="deleteAccountModal" tabindex="-1" aria-labelledby="deleteAccountModalLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content rounded-0">
                                        <div className="modal-header">
                                            <h1 className="modal-title heading fs2rem" id="deleteAccountModalLabel">Delete Account</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            Are you sure you want to delete your account?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary rounded-0" data-bs-dismiss="modal">Cancel</button>
                                            <button onClick={() => { dispatch(logout()); handleLogout(); dispatch(deleteAccount(userInfo.id)) }} type="button" data-bs-dismiss="modal" class="btn btn-danger rounded-0">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-3'> */}
                        {/* <img src={userProfile} alt='user-image' className='img-fluid' /> */}
                        {/* <img src={`${baseURL}/${userInfo?.image}`} alt='user-image' className='img-fluid' /> */}
                        {/* <img src={userInfo?.image ? `${baseURL}/${userInfo?.image}` : userProfile} alt='user image' className='img-fluid' /> */}
                        {/* </div> */}


                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header">
                                        <h1 className="modal-title heading fs2rem" id="staticBackdropLabel">Edit Profile</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <InputComponent onChange={handleChange} value={editProfile.f_name} type='text' className="form-control" id='' name='f_name' label='First Name' />
                                                </div>
                                                <div className='col-md-6'>
                                                    <InputComponent onChange={handleChange} value={editProfile.l_name} type='text' className="form-control" id='' name='l_name' label='Last Name' />
                                                </div>
                                                <div className='col-xl-6 col-lg-6 col-md-6'>
                                                    <PhoneInput
                                                        placeholder="Enter phone number"
                                                        defaultCountry="EE"
                                                        value={value}
                                                        onChange={setValue}
                                                    />
                                                </div>
                                                   <div className='col-md-6'>
                                                    <InputComponent onChange={handleChange} value={editProfile.password} type='password' className="form-control" id='' name='password' label='Password' />
                                                </div>                                           
                                            </div>
                                            <div className="modal-footer">
                                                <button type="submit" className="btn btn-dark rounded-0" data-bs-dismiss="modal">Update Profile</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Profile
