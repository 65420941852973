import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InputComponent from '../Components/InputComponent'
import { login } from '../redux/features/authSlice'
import { addressListFetch, userInfoFetch } from '../redux/features/userSlice'
import { getWishList } from '../redux/features/wishlistSlice'


const LoginPage = () => {

    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginToken = useSelector((state)=>state.auth.loginToken)
    const config = JSON.parse(localStorage.getItem("config"))
    const errors = useSelector((state)=>state.auth.loginError)
    const baseURL = config?.base_urls?.ecommerce_image_url


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(loginData)
        dispatch(login(JSON.stringify(loginData)))
        dispatch(userInfoFetch())
        dispatch(getWishList())
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    if(loginToken){
        navigate('/')
        window.location.reload();
        scrollToTop();
    }


    
   


    const handleChange = (event) => {
        const { name, value } = event.target;
        setLoginData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };



    return (
        <div>
            <main className='signup__outer' >
                <img src={`${baseURL}/${config.ecommerce_logo}`} className="logo" alt={config.ecommerce_name} />
                <h3 className='text-center my-4'>Log in</h3>
                {errors ? (errors.map((error) => ((error.code === "auth-001") ? <p className='error' >{error.message}</p> : null))) : null}
                <div className='w-5' >
                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className='row mt-4'>
                            <div className='col-xl-12 col-lg-12 col-md-12'>
                                <InputComponent
                                    value={loginData.email}
                                    onChange={handleChange}
                                    label='Email' type='email' id='email' name='email' />
                            </div>
                            {errors ? (errors.map((error) => ((error.code === "email") ? <p className='error' >{error.message}</p> : null))) : null}
                           <div className='col-xl-12 col-lg-12 col-md-12'>
                                <InputComponent
                                    value={loginData.password}
                                    onChange={handleChange}
                                    label='Password' type='password' id='password' name='password' />
                            </div>
                            {errors ? (errors.map((error) => ((error.code === "password") ? <p className='error' >{error.message}</p> : null))) : null}                       
                        </div>
                        <div className='w-5' >
                            <button type="submit" onClick={()=>{dispatch(addressListFetch())}} className="signup__btn btn">Log in</button>
                            {/* <Link to='/forget-password' ><h6 className='login-signup text-center mt-1 pointer'>Forget Password?</h6></Link> */}
                            <p className='text-center my-2'>Create an account <Link to='/signup'><span className='login-signup' >Signup</span></Link></p>
                        </div>
                    </form> 
                </div>
            </main>
        </div>
    )
}

export default LoginPage;

