import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../axios' 


const initialState={
    config:[],
    categories:[],
    banner:[],
    notification:[],
    status:"loading",
    searchItem:[]
}




export const configFetch = createAsyncThunk(
  "configFetch" ,
  async()=>{
    const response = await axios.get('/config')
    localStorage.setItem("config", JSON.stringify(response?.data))
    return response?.data
      }
)

export const categoriesFetch = createAsyncThunk(
  "categoriesFetch" ,
  async()=>{
    const response= await axios.get("/categories")
    localStorage.setItem("categories", JSON.stringify(response?.data))
    return response?.data
      }
)

export const bannerFetch = createAsyncThunk(
  "bannerFetch" ,
  async()=>{
    const response= await axios.get("/banners")
    localStorage.setItem("banner", JSON.stringify(response?.data))
    return response?.data
      }
)


export const notificationFetch = createAsyncThunk(
  "notificationFetch" ,
  async()=>{
    const response= await axios.get("/notifications")
    return response?.data
      }
)


export const searchFetch = createAsyncThunk(
  "searchFetch" ,
  async(search)=>{
    const response= await axios.get(`/products/search?name=${search}`)
    return response?.data
      }
)

const configSlice = createSlice({
    name:"config",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(configFetch.fulfilled,(state, action) => { 
           state.config = action.payload
           state.status = "fulfilled"

          })
          .addCase(configFetch.rejected,(state, action) => {
            state.status = "rejected";        
          })
          .addCase(categoriesFetch.fulfilled,(state, action) => { 
           state.categories = action.payload
           state.status = "fulfilled"

          })
          .addCase(categoriesFetch.rejected,(state, action) => {
            state.status = "rejected";        
          })
          .addCase(bannerFetch.fulfilled,(state, action) => { 
           state.banner = action.payload
           state.status = "fulfilled"

          })
          .addCase(bannerFetch.rejected,(state, action) => {
            state.status = "rejected";        
          })
           .addCase(searchFetch.fulfilled,(state, action) => {
            state.searchItem = action.payload;
            console.log(state.searchItem, 'search')        
          }) .addCase(notificationFetch.fulfilled,(state, action) => {
            state.notification = action.payload;
          })                 
      },
})


const { reducer } = configSlice;
export default reducer;
