import { configureStore } from '@reduxjs/toolkit'
import configSlice from './features/configSlice'
import productSlice from './features/productSlice'
import singleProductSlice from './features/singleProductSlice'
import allProductSlice from './features/allProductSlice'
import cartSlice from './features/cartSlice'
import authSlice from './features/authSlice'
import wishlistSlice from './features/wishlistSlice'
import userSlice from './features/userSlice'
import orderSlice from './features/orderSlice'





export const store = configureStore({
  reducer: {
    config: configSlice,
    products: productSlice,
    singleProduct: singleProductSlice,
    allProducts: allProductSlice,
    cart: cartSlice,
    auth: authSlice,
    wishlist: wishlistSlice,
    order: orderSlice,
    userInfo: userSlice,
  },

})

