import React from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTypography,
} from "mdb-react-ui-kit";
import { BiCheck } from "react-icons/bi";
import { useSelector } from "react-redux";

export default function TrackOrder() {



    const trackingDetails = useSelector((state) => state?.order?.trackingDetails)
    const config = JSON.parse(localStorage.getItem("config"))



    console.log(trackingDetails, 'component')



    return (
        <>
            <section className="vh-100" style={{ backgroundColor: "#eee" }}>
                <MDBContainer className="h-100">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol>
                            <MDBCard
                                className="card-stepper"
                                style={{ borderRadius: "10px" }}
                            >
                                <MDBCardBody className="p-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex flex-column">
                                            <span className="lead fw-normal">
                                                Track Your Order
                                            </span>
                                           
                                            <span className="small">
                                                Total Amount:{config?.currency_symbol}{trackingDetails?.order_amount}
                                            </span>
                                        </div>
                                        <div>
                                        <span className="text-muted small">
                                                Order ID:#{trackingDetails?.id}
                                            </span>
                                        </div>
                                    </div>

                                    <hr className="my-4" />

                                    <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
                                        <span className={`dot d-flex justify-content-center align-items-center ${trackingDetails.checked == 0 ? 'big-dot' : null}`}>
                                            {trackingDetails.checked == 0 ? <BiCheck className="text-white" /> : null}
                                        </span>
                                        <hr className="flex-fill track-line" />
                                        <span className={`dot d-flex justify-content-center align-items-center ${trackingDetails.checked == 0 ? 'big-dot' : null}`}>
                                            {trackingDetails.checked == 0 ? <BiCheck className="text-white" /> : null}
                                        </span>
                                        <hr className="flex-fill track-line" />
                                        <span className={`dot d-flex justify-content-center align-items-center ${trackingDetails.checked == 0? 'big-dot' : null}`}>
                                            {trackingDetails.checked == 0 ? <BiCheck className="text-white" /> : null}
                                        </span>
                                        <hr className="flex-fill track-line" />
                                        <span className={`dot d-flex justify-content-center align-items-center ${trackingDetails.checked == 1 ? 'big-dot' : null}`}>
                                            {trackingDetails.checked == 1 ? <BiCheck className="text-white" /> : null}
                                        </span>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className="d-flex flex-column align-items-start">
                                            <span>Order placed</span>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <span>processing</span>
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <span>Shipped</span>
                                        </div>
                                        <div className="d-flex flex-column align-items-end">
                                            <span>Delivered</span>
                                        </div>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
        </>
    );
}