import React from 'react'
import aboutusimg from '../assets/img/aboutus/aboutus.webp'
import HomeCategory from '../Components/HomeCategory'

const AboutUs = () => {
    return (
        <div className='container' >
            <div className='about_us_grid' >
                <div >
                    <h3 className='heading'>Elevate Your Hair Game with Hair For Free!</h3>
                    <p className='aboutus-text'>At Hair For Free, we believe that every individual deserves to feel confident and beautiful. Our journey began with a simple yet powerful mission: to provide top-quality, natural hair products that empower you to embrace your unique beauty.</p>
                </div>
                <div className='bg-light'>
                    <img src={aboutusimg} className='img-fluid' alt='a girl combing her hairs' />
                </div>
            </div>
            <HomeCategory />
        </div>
    )
}

export default AboutUs
