import React from 'react'
import { useSelector } from 'react-redux'
import { ImSad2 } from 'react-icons/im'
import { Link } from 'react-router-dom'

const Notification = () => {

  const notification = useSelector((state) => state.config.notification)






  return (
    <div className='h-100' >
      {notification?.length == 0 ? <div className='container my-5 text-center' >
        <p className='heading' >No Notification<ImSad2 className='sad-icon' /></p>
        <Link to='/' ><p className='slide-btn'>Home</p></Link>
      </div> :     
        <div className='container vh-100 my-5' >
        <h3 className='heading' >Notification</h3>
          <p className='notification-msg' >new notification</p>
      </div>
      }
    </div>
  )
}

export default Notification
