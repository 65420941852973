import { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';
import { useDispatch } from 'react-redux';
import { fetchLatLng } from '../redux/features/userSlice';
  

  const Location = ({setFullAddress, setCurrentPosition}) => {

    const dispatch = useDispatch()

    const [address, setAddress] = useState("")
    const [coordinates, setCoordinates]= useState({
        lat:null,
        lng:null
    })


    const handleSelect = async value =>{
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0])
        console.log(ll,'lat-lng')
        setAddress(value);
        setFullAddress(value);
        setCoordinates(ll);
        if(setCurrentPosition){

          setCurrentPosition(ll)
        }
        dispatch(fetchLatLng(ll))
    }


    return (
      <div>
        <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Your Address ...',
                className:  'form-control location-search-input p-3 rounded-0',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      </div>
    )
  }
  
  export default Location
  