import React from 'react'
import InputComponent from '../Components/InputComponent'
import Location from '../Components/Location'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addressListFetch } from '../redux/features/userSlice';
import UserAddress from '../Components/UserAddress'
import { placeOrder, removeid } from '../redux/features/orderSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
    const [digitalPayment, setDigitalPayment] = useState(false);
    const [cod, setCod] = useState(false)
    const [fullAddress, setFullAddress] = useState("")
    const [selectedAddress, setSelectedAddress] = useState({})
    const [userAddress, setUserAddress] = useState({
        id: "",
        address_type: "",
        contact_person_number: "",
        address: "",
        latitude: "",
        longitude: "",
        created_at: "",
        updated_at: "",
        user_id: "",
        method: "",
        contact_person_name: ""
    })


    const coordinate = useSelector((state) => state.userInfo)
    const [currentPosition, setCurrentPosition] = useState({
        lat: 22.7411203,
        lng: 75.9185608
    });


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const state = useSelector((state) => state?.userInfo)
    const cancelOrdermsg = useSelector((state) => state.order?.cancelOrdermsg)
    const orderPlacedmsg = useSelector((state) => state.order)
    const addressList = JSON.parse(localStorage.getItem("address"))
    const cartItems = JSON.parse(localStorage.getItem("cart"))
    const config = JSON.parse(localStorage.getItem("config"))
    const baseURL = config?.base_urls?.product_image_url;
    const user = JSON.parse(localStorage.getItem("user"))
    const id = useSelector((state) => state?.order)



    console.log(orderPlacedmsg, 'state order')

    const getTotal = cartItems?.reduce((total, currentItem) => {
        if (!(currentItem.product_data.total_stock < currentItem.quantity)) {
            return total + currentItem.product_data.price * currentItem.quantity;
        }
        return total;
    }, 0);


    useEffect(() => {
        if (id?.id && digitalPayment) {
            window.location.replace(`https://hairforfree.com/api/payment-mobile?order_id=${id.id}&&customer_id=${user.id}`);
        }
        removeid();

    }, [id])

    const notifyOrder = () => toast(`${orderPlacedmsg?.orderPlacedmsg?.message}`, {
        autoClose: 3000,
        hideProgressBar: true,
    });

    useEffect(() => {
        if (id.id && cod) {
            notifyOrder();
            navigate('/order-placed')
        }
        removeid();
    }, [id])


    const productList = [];


    cartItems?.forEach((currentItem) => {
        if (!(currentItem.product_data.total_stock < currentItem.quantity)) {
            productList.push(currentItem);
        }
    });




    useEffect(() => {
        dispatch(addressListFetch())
    }, [])
    useEffect(() => {
        state?.address?.message && dispatch(addressListFetch())
    }, [state?.address?.message])



    const FillDetail = () => toast(`Please Fill All Details`, {
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });


    const orderAmount = getTotal < 50 ? getTotal + 6.50 : getTotal

    const [order, setOrder] = useState(
        {
            cart: [
                ...productList
            ],
            coupon_discount_amount: "0.0",
            coupon_discount_title: "",
            order_amount: orderAmount?.toFixed(2),
            order_type: "delivery",
            payment_method: "",
            order_note: "",
            coupon_code: "",
            branch_id: "1",
            distance: "-1.0",
            request_from: "web"
        }
    )




    const inputsHandler = (event) => {
        const { name, value } = event.target;
        setOrder((prevOrderData) => {
            const updatedOrderData = { ...prevOrderData, [name]: value };
            console.log(updatedOrderData, 'radioooo');
            return updatedOrderData; 
        });
    };



    const handleOrder = () => {
        if (selectedAddress?.id !== undefined && order.payment_method !== "") {
            if (order.payment_method === 'cash_on_delivery') {
                dispatch(placeOrder(JSON.stringify({ ...order, delivery_address_id: selectedAddress.id })));
                removeid();
                setCod(true)
                localStorage.removeItem("cart");
            } else {
                dispatch(placeOrder(JSON.stringify({ ...order, delivery_address_id: selectedAddress.id })));
                setDigitalPayment(true)
                localStorage.removeItem("cart");
            }
        } else {
            FillDetail();
        }
    };





    const success = position => {
        setCurrentPosition(coordinate);
    };


    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    }, [currentPosition, coordinate])

    const mapStyles = {
        height: "300px",
        width: "100%"
    };

    return (
        <div className='container-fluid'>
            {addressList?.length == 0 ? null : <h3 className='mt-4'>Select Address</h3>}
            <div className='row mb-5'>
                {/* <div className='my-3' >

                    <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={13}
                        center={currentPosition}
                    >
                        {

                            currentPosition.lat && (<Marker position={currentPosition} />
                            )
                        }
                    </GoogleMap>
                </div> */}
                <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4'>
                    <UserAddress column='12' setSelectedAddress={setSelectedAddress} />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-4'>
                    <h5 class="border border-bottom-0 m-0 p-3">Payment</h5>
                    <div className='border container-fluid'>

                        <div onChange={inputsHandler} >
                            <div class="border d-flex gap-2 px-2 py-3 my-2">
                                <input type="radio" id='cash' class="form-check-input" value="cash_on_delivery" name="payment_method" checked={order.payment_method === "cash_on_delivery"} />
                                <label class="form-check-label" htmlFor='cash'>Cash on delivery</label>
                            </div>
                            <div class="border d-flex gap-2 px-2 py-3 my-2">
                                <input type="radio" id='digital' class="form-check-input" value="digital_payment" name="payment_method" checked={order.payment_method === "digital_payment"} />
                                <label class="form-check-label" htmlFor='digital'>digital payment</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-4'>
                    <h5 class="border border-bottom-0 m-0 p-3">Your Order</h5>
                    <div className='border container-fluid table-responsive'>
                        <table className='table mt-2 table-bordered'>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Number</th>
                                    <th>Subtotal</th>
                                </tr>
                                {productList?.map((item) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className='d-flex gap-2'>
                                                    <img src={`${baseURL}/${JSON.parse(item?.product_data.image)[0].replace(/"[]/g, '')}`} width='50px' height='50px' alt={item.name} />
                                                    <div className='align-items-center d-flex fs12'>
                                                        {item?.product_data.name}
                                                    </div>
                                                </td>
                                                <td>{item?.quantity}</td>
                                                <td>{config?.currency_symbol}{item?.product_data.price} </td>
                                            </tr>
                                        </>

                                    )
                                })}



                                <tr>
                                    <th>Shipping Charges</th>
                                    <td colSpan={2}>
                                        {getTotal < 50 ? <p>{config?.currency_symbol}6.50</p> : <p>{config?.currency_symbol}0</p>}
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>Total</th>
                                    {getTotal < 50 ? <th className="fs-5" >{config?.currency_symbol}{(getTotal + 6.50)?.toFixed(2)}</th> : <th className="fs-5" >{config?.currency_symbol}{getTotal?.toFixed(2)}</th>}
                                </tr>
                            </thead>
                        </table>

                        <p className='text-danger'>*Free shipping for orders above {config?.currency_symbol}50.</p>
                        <ToastContainer />
                        <button onClick={() => { handleOrder(); }} type="button" class="btn btn-dark mt-3 py-3 rounded-0 w-100">Place Order</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Checkout