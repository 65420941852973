import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InputComponent from '../Components/InputComponent'
import { signout, signup } from '../redux/features/authSlice'


const SignupPage = () => {
    const dispath = useDispatch()
    const errors = useSelector((state) => state.auth?.SignupError)
    const token = useSelector((state)=>state.auth.token)
    const isRegistered = useSelector((state) => state.auth.isRegistered)
    const [value, setValue] = useState()
    const [signupData, setSignupData] = useState({
        f_name: "",
        l_name: "",
        email: "",
        phone: "",
        password: ""
    })
    const navigate = useNavigate()
    const config = JSON.parse(localStorage.getItem("config"))
    const baseURL = config?.base_urls?.ecommerce_image_url


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(JSON.stringify({ ...signupData, "phone": value }))
        dispath(signup(JSON.stringify({ ...signupData, "phone": value })))

    };
      

useEffect(() => {
    
    if(token){     
        navigate("/login")
    }
    dispath(signout())

}, [handleSubmit])


    const handleChange = (event) => {
        const { name, value } = event.target;
        setSignupData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };




    return (
        <div>
            <main className='signup__outer' >
                <img src={`${baseURL}/${config.ecommerce_logo}`} className="logo" alt={config.ecommerce_name} />
                <h3 className='text-center my-4'>Sign Up</h3>
                <form onSubmit={handleSubmit}  >
                    <div className='row mt-4'>
                        <div className='col-xl-6 col-lg-6 col-md-6'>
                            <InputComponent value={signupData.f_name} onChange={handleChange} label='First Name' type='text' id='f_name' name='f_name' />
                            {errors ?( errors.map((error) => ((error.code === "f_name") ? <p className='error' >{error.message}</p> : null))) : null}
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6'>
                            <InputComponent value={signupData.l_name} onChange={handleChange} label='Last Name' type='text' id='l_name' name="l_name" />
                            {errors ?( errors.map((error) => ((error.code === "l_name") ? <p className='error' >{error.message}</p> : null))) : null}
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12'>
                            <InputComponent value={signupData.email} onChange={handleChange} label='Email' type='email' id='email' name='email' />
                            {errors ? (errors.map((error) => ((error.code === "email") ? <p className='error' >{error.message}</p> : null))) : null}
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12'>
                            <InputComponent value={signupData.password} onChange={handleChange} label='Password' type='password' id='password' name='password' />
                            {errors ? (errors.map((error) => ((error.code === "password") ? <p className='error' >{error.message}</p> : null))) : null}
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12'>
                            <PhoneInput
                                placeholder="Enter phone number"
                                defaultCountry="EE"
                                value={value}
                                onChange={setValue}
                            />
                            {errors ? (errors.map((error) => ((error.code === "phone") ? <p className='error' >{error.message}</p> : null))) : null}
                        </div>
                    </div>
                    <div className='w-5' >
                        <button type="submit" className="signup__btn btn">Sign Up</button>
                        <p className='text-center my-2'>Already have an account <Link to='/login'><span className='login-signup' >Login</span></Link></p>
                    </div>
                </form>
            </main>
        </div>
    )
}

export default SignupPage
