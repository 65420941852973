import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addressDelete, addressListFetch } from '../redux/features/userSlice'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useEffect } from 'react'
import { useState } from 'react'
import AddAddress from './AddAddress'
import EditAddress from './EditAddress'
import { Link } from 'react-router-dom'
import { MdOutlineModeEdit } from 'react-icons/md'

const  UserAddress = ({setSelectedAddress,column}) => {


    console.log(typeof(setSelectedAddress), 'typeeeee')

    const [userAddress1, setUserAddress1] = useState(useSelector((state) => state.userInfo.addressList))
    const [updateAddress, setUpdateAddress] = useState(null);
    const [statee, setStatee] = useState(null)
    const dispatch = useDispatch()
    const state = useSelector((state) => state?.userInfo)
    const userAddress = JSON.parse(localStorage.getItem("addressList"))
    const token = localStorage.getItem("logintoken")



    useEffect(() => {
        state?.address?.message && dispatch(addressListFetch())
        console.log(state?.address?.message, 'yolo')
    }, [state?.address?.message])

    useEffect(() => {
        dispatch(addressListFetch())
    }, [])



    const handleAddress =(address)=>{
        setStatee(address);
        if(setSelectedAddress){

            setSelectedAddress({...address})
        }
    }



    return (
        <>
            {userAddress || token ?
                <>
                    {userAddress ? userAddress.map((address) => (
                        <div className={`col-sm-12 col-md-${column} col-lg-${column} col-xl-${column} col-xxl-${column} mt-4`}>
                            <div className='card bg-grey h-100 addressBox'>
                                <div className='fw-medium mb-2 fs18'>
                                    <div class="border d-flex gap-2 px-2 py-3 my-2">
                                        <input class="form-check-input" name='address' type="radio" value={address.id} 
                                        onChange={()=>handleAddress(address)} id={address.id} key={address.id} />
                                        <label class="form-check-label" htmlFor={address.id}>
                                            Deliver to this adderss
                                        </label>
                                    </div>
                                </div>
                                <div className='fw-medium mb-2 addressTY'>
                                    {address?.address_type}
                                </div>
                                <div className='fw-medium mb-2'>
                                    {address?.contact_person_name}
                                </div>
                                <div className='fw-medium mb-2 fs14'>
                                    {address?.contact_person_number}
                                </div>
                                <div className='fw-medium mb-2 fs14'>
                                    {address?.address}
                                </div>


                                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <EditAddress editAddress={updateAddress} setEditAddress={setUpdateAddress} />
                                            </div>
                                        </div>
                                    </div>
                                <div className='d-flex gap-2 btnBottom'>
                                    <button type="button" onClick={() => { setUpdateAddress({ ...address }) }} class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#editModal">
                                        <MdOutlineModeEdit /> Edit
                                    </button>
                                    <button className='btnDelete' onClick={() => { dispatch(addressDelete(address.id)); dispatch(addressListFetch()) }}>
                                        <RiDeleteBin5Line /> Delete
                                    </button>
                                </div>

                            </div>
                        </div>
                    )) : null}
                    <div>
                        <div class="modal fade" id="addressModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <AddAddress />
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-dark my-3" data-bs-toggle="modal" data-bs-target="#addressModal">
                            Add New Address
                        </button>
                    </div>
                </> :
                <div className=' align-items-center container d-flex flex-column justify-content-center mt-3 user-div' >
                    <p className='heading' >you are not logged in</p>
                    <Link to='/login'><p className='slide-btn'>log in</p></Link>
                </div>
            }

        </>
    )
}

export default UserAddress
